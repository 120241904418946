import actionTypes, {
  QUESTIONS_BANK,
  QUESTIONS_BANK_DELETE,
  QUESTIONS_BANK_LIST,
  QUESTIONS_BANK_ALL,
  QUESTIONS_BANK_CREATE,
  QUESTIONS_BANK_ADD_TEACHERS,
  QUESTIONS_BANK_REMOVE_TEACHERS,
} from '../constants/actionTypes'

export const questionsBank = payload => ({
  type: actionTypes[QUESTIONS_BANK].pending,
  payload,
})

export const questionsBankClear = () => ({
  type: actionTypes[QUESTIONS_BANK].clear,
})

export const questionsBankList = payload => ({
  type: actionTypes[QUESTIONS_BANK_LIST].pending,
  payload,
})

export const questionsBankListClear = () => ({
  type: actionTypes[QUESTIONS_BANK_LIST].clear,
})

export const questionsBankAll = payload => ({
  type: actionTypes[QUESTIONS_BANK_ALL].pending,
  payload,
})

export const questionsBankAllClear = () => ({
  type: actionTypes[QUESTIONS_BANK_ALL].clear,
})

export const questionsBankCreate = payload => ({
  type: actionTypes[QUESTIONS_BANK_CREATE].pending,
  payload,
})

export const questionsBankCreateClear = () => ({
  type: actionTypes[QUESTIONS_BANK_CREATE].clear,
})

export const questionsBankDelete = payload => ({
  type: actionTypes[QUESTIONS_BANK_DELETE].pending,
  payload,
})

export const questionsBankDeleteClear = () => ({
  type: actionTypes[QUESTIONS_BANK_DELETE].clear,
})

export const questionsBankAddTeachers = payload => ({
  type: actionTypes[QUESTIONS_BANK_ADD_TEACHERS].pending,
  payload,
})

export const questionsBankRemoveTeachers = payload => ({
  type: actionTypes[QUESTIONS_BANK_REMOVE_TEACHERS].pending,
  payload,
})