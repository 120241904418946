import { createAction } from '../../../helpers/createAction'

export const ASSESSMENT_LIST = 'ASSESSMENT_LIST'
export const ASSESSMENT_CREATE = 'ASSESSMENT_CREATE'
export const ASSESSMENT = 'ASSESSMENT'
export const ASSESSMENT_DELETE = 'ASSESSMENT_DELETE'
export const ASSESSMENT_ALL = 'ASSESSMENT_ALL'
export const ASSESSMENT_TYPES = 'ASSESSMENT_TYPES'
export const ASSESSMENT_RESULTS = 'ASSESSMENT_RESULTS'
export const ASSESSMENT_IMPORT_PAPER_BASED = 'ASSESSMENT_IMPORT_PAPER_BASED'
export const ASSESSMENT_GROUPS_IMPORT_BY_STUDENT = 'ASSESSMENT_GROUPS_IMPORT_BY_STUDENT'

export default {
  [ASSESSMENT_LIST]: createAction(ASSESSMENT_LIST),
  [ASSESSMENT_CREATE]: createAction(ASSESSMENT_CREATE),
  [ASSESSMENT]: createAction(ASSESSMENT),
  [ASSESSMENT_DELETE]: createAction(ASSESSMENT_DELETE),
  [ASSESSMENT_ALL]: createAction(ASSESSMENT_ALL),
  [ASSESSMENT_TYPES]: createAction(ASSESSMENT_TYPES),
  [ASSESSMENT_RESULTS]: createAction(ASSESSMENT_RESULTS),
  [ASSESSMENT_IMPORT_PAPER_BASED]: createAction(ASSESSMENT_IMPORT_PAPER_BASED),
  [ASSESSMENT_GROUPS_IMPORT_BY_STUDENT]: createAction(ASSESSMENT_GROUPS_IMPORT_BY_STUDENT),
}
