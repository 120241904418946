import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '../../../../helpers/createState'

const reducer = combineReducers({
  [STATE.MODULES]: createState(actionTypes.MODULES),
  [STATE.MODULES_LIST]: createState(actionTypes.MODULES_LIST),
  [STATE.MODULES_ALL]: createState(actionTypes.MODULES_ALL),
})

export const MODULES = STATE.GLOBAL_STATE

export default reducer
