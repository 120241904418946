import { createAction } from '../../../helpers/createAction'

export const TEACHING_GROUP = 'TEACHING_GROUP'
export const TEACHING_GROUP_CREATE = 'TEACHING_GROUP_CREATE'
export const TEACHING_GROUP_DELETE = 'TEACHING_GROUP_DELETE'
export const TEACHING_GROUP_ALL = 'TEACHING_GROUP_ALL'
export const TEACHING_GROUP_LIST = 'TEACHING_GROUP_LIST'
export const TEACHING_GROUP_GRADES = 'TEACHING_GROUP_GRADES'
export const TEACHING_GROUP_STUDENTS = 'TEACHING_GROUP_STUDENTS'
export const TEACHING_GROUP_TEACHERS = 'TEACHING_GROUP_TEACHERS'
export const TEACHING_GROUP_TYPES = 'TEACHING_GROUP_TYPES'

export default {
  [TEACHING_GROUP]: createAction(TEACHING_GROUP),
  [TEACHING_GROUP_CREATE]: createAction(TEACHING_GROUP_CREATE),
  [TEACHING_GROUP_DELETE]: createAction(TEACHING_GROUP_DELETE),
  [TEACHING_GROUP_ALL]: createAction(TEACHING_GROUP_ALL),
  [TEACHING_GROUP_LIST]: createAction(TEACHING_GROUP_LIST),
  [TEACHING_GROUP_GRADES]: createAction(TEACHING_GROUP_GRADES),
  [TEACHING_GROUP_STUDENTS]: createAction(TEACHING_GROUP_STUDENTS),
  [TEACHING_GROUP_TEACHERS]: createAction(TEACHING_GROUP_TEACHERS),
  [TEACHING_GROUP_TYPES]: createAction(TEACHING_GROUP_TYPES),
}