import actionTypes, {
  STUDENT_ASSESSMENT,
  STUDENT_ASSESSMENT_SAVE,
  STUDENT_ASSESSMENT_SELECT,
  STUDENT_ASSESSMENT_SUBMIT,
} from '../constants/actionTypes'

export const studentAssessment = payload => ({
  type: actionTypes[STUDENT_ASSESSMENT].pending,
  payload,
})

export const studentAssessmentClear = () => ({
  type: actionTypes[STUDENT_ASSESSMENT].clear,
})

export const studentAssessmentSave = payload => ({
  type: actionTypes[STUDENT_ASSESSMENT_SAVE].pending,
  payload,
})

export const studentAssessmentSubmit = payload => ({
  type: actionTypes[STUDENT_ASSESSMENT_SUBMIT].pending,
  payload,
})

export const studentAssessmentSelect = payload => ({
  type: actionTypes[STUDENT_ASSESSMENT_SELECT].pending,
  payload,
})
