/* ACCOUNT */
import { createAction } from '../../../helpers/createAction'

export const ACCOUNT = 'ACCOUNT'
export const ACCOUNT_CREATE = 'ACCOUNT_CREATE'
export const ACCOUNT_DELETE = 'ACCOUNT_DELETE'
export const ACCOUNT_LIST = 'ACCOUNT_LIST'
export const ACCOUNT_ALL = 'ACCOUNT_ALL'
export const ACCOUNT_PASSWORD_RESET = 'ACCOUNT_PASSWORD_RESET'
export const ACCOUNT_REQUIRED_ACTIONS = 'ACCOUNT_REQUIRED_ACTIONS'
export const ACCOUNT_PROFILE_PIC = 'ACCOUNT_PROFILE_PIC'
export const ACCOUNT_PROFILE_UPDATE = 'ACCOUNT_PROFILE_UPDATE'
export const ACCOUNT_PASSWORD_UPDATE = 'ACCOUNT_PASSWORD_UPDATE'

export default {
  [ACCOUNT]: createAction(ACCOUNT),
  [ACCOUNT_CREATE]: createAction(ACCOUNT_CREATE),
  [ACCOUNT_DELETE]: createAction(ACCOUNT_DELETE),
  [ACCOUNT_LIST]: createAction(ACCOUNT_LIST),
  [ACCOUNT_ALL]: createAction(ACCOUNT_ALL),
  [ACCOUNT_PASSWORD_RESET]: createAction(ACCOUNT_PASSWORD_RESET),
  [ACCOUNT_REQUIRED_ACTIONS]: createAction(ACCOUNT_REQUIRED_ACTIONS),
  [ACCOUNT_PROFILE_PIC]: createAction(ACCOUNT_PROFILE_PIC),
  [ACCOUNT_PROFILE_UPDATE]: createAction(ACCOUNT_PROFILE_UPDATE),
  [ACCOUNT_PASSWORD_UPDATE]: createAction(ACCOUNT_PASSWORD_UPDATE),
}
