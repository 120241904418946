import { prop, dissoc } from 'ramda/es'
import { getInstance } from '../../../../helpers/httpClient'
import * as API from '../constants/api'

export const modules = data =>
  getInstance().get(`${API.MODULES}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const modulesCreate = data => getInstance().post(API.MODULES, data)

export const modulesDelete = data => getInstance().delete(`${API.MODULES}${prop('id', data)}`)

export const modulesList = data =>
  getInstance().get(API.MODULES, {
    params: data,
  })

export const modulesAll = data =>
  getInstance().get(API.MODULES_ALL, {
    params: data,
  })
