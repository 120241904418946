import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'
import { DefaultState } from '@src/redux/types'

import { GLOBAL_STATE, SUBJECT_ALL } from '../constants/actionTypes'
import * as API from '../constants/api'
import { subjectAll, subjectAllClear } from './actions'

const defaultState = () => ({ loading: false, error: null, data: null })

const initialState: () => {
  [SUBJECT_ALL]: DefaultState<`${typeof API.SUBJECT_ALL}`, 'get'>
} = () => ({
  [SUBJECT_ALL]: defaultState(),
})

export const SUBJECT = GLOBAL_STATE

export const subjectSlice = createSlice({
  name: SUBJECT,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(subjectAll.pending, state => {
        state[SUBJECT_ALL].loading = true
      })
      .addCase(subjectAll.rejected, (state, action) => {
        state[SUBJECT_ALL].loading = false
        state[SUBJECT_ALL].error = action.payload as AxiosError
      })
      .addCase(subjectAll.fulfilled, (state, action) => {
        state[SUBJECT_ALL].loading = false
        state[SUBJECT_ALL].error = null
        state[SUBJECT_ALL].data = action.payload
      })

    builder.addCase(subjectAllClear, state => {
      state[SUBJECT_ALL] = defaultState()
    })
  },
})

export const { reducer: subjectReducer } = subjectSlice
