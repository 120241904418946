// ** React Imports
// ** Core styles
// ** Ripple Button
import '@components/ripple-button'
// ** Spinner (Splash Screen)
import Spinner from '@components/spinner/Fallback-spinner'
// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'
// ** PrismJS
import 'prismjs'
import 'prismjs/components/prism-jsx.min'
import 'prismjs/themes/prism-tomorrow.css'
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'
// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'
// ** Redux Imports
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
// ** Toast & ThemeColors Context
import ability from './configs/acl/ability'
import { store } from './redux/store'
// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { AbilityContext } from './utility/context/Can'
// ** Intl
import { IntlProviderWrapper } from './utility/context/Internationalization'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

// const SentryWrapper = props => {
//   const userInfo = useSelector(authUserInfo)
//   const children = propOr(null, 'children', props)

//   useEffect(() => {
//     if (userInfo) {
//       Sentry.setContext('userInfo', userInfo)
//     }
//   }, [userInfo?.id])

//   useEffect(() => {
//     sentryInit({
//       configs: {
//         release: process.env.REACT_APP_SENTRY_RELEASE,
//         integrations: [
//           history
//             ? new BrowserTracing({
//                 routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
//               })
//             : new BrowserTracing(),
//         ],
//         normalizeDepth: 6,
//         beforeSend(event, hint) {
//           return event
//         },
//         tracesSampler: samplingContext => {
//           // if ('...') {
//           //   // Drop this transaction, by setting its sample rate to 0%
//           //   return 0
//           // } else {
//           //   // Default sample rate for all others (replaces tracesSampleRate)
//           //   return 0.1
//           // }
//           return 1.0
//         },
//       },
//     })
//   }, [])

//   const errorFallback = (
//     <Alert color='danger'>
//       <div className='alert-body'>
//         <AlertCircle size={15} /> <span className='ms-1'>An error has occurred.</span>
//       </div>
//     </Alert>
//   )

//   return (
//     <Sentry.ErrorBoundary
//       fallback={errorFallback}
//       beforeCapture={scope => {
//         scope.setContext()
//       }}
//     >
//       {children}
//     </Sentry.ErrorBoundary>
//   )
// }

const Wrapper = () => {
  // useEffect(() => {
  //   !userId && localStorage.clear()
  // }, [userId])

  return (
    <Provider store={store}>
      <IntlProviderWrapper defaultLocale='uz'>
        <Suspense fallback={<Spinner />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              <LazyApp />
              <ToastContainer newestOnTop />
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
      </IntlProviderWrapper>
      {/* </SentryWrapper> */}
    </Provider>
  )
}

ReactDOM.render(<Wrapper />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
