import actionTypes, {
  TEACHER,
  TEACHER_DASHBOARD,
  TEACHER_ACTION_MADE,
  TEACHER_ALL
} from '../constants/actionTypes'


export const teacher = payload => ({
  type: actionTypes[TEACHER].pending,
  payload,
})

export const teacherClear = () => ({
  type: actionTypes[TEACHER].clear,
})

export const teacherDashboard = payload => ({
  type: actionTypes[TEACHER_DASHBOARD].pending,
  payload,
})

export const teacherDashboardClear = () => ({
  type: actionTypes[TEACHER_DASHBOARD].clear,
})

export const teacherActionMade = payload => ({
  type: actionTypes[TEACHER_ACTION_MADE].pending,
  payload,
})

export const teacherActionMadeClear = () => ({
  type: actionTypes[TEACHER_ACTION_MADE].clear,
})

export const teacherAll = payload => ({
  type: actionTypes[TEACHER_ALL].pending,
  payload,
})

export const teacherAllClear = () => ({
  type: actionTypes[TEACHER_ALL].clear,
})