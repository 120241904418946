import { createAction } from '../../../helpers/createAction'

export const QUESTION_LIST = 'QUESTION_LIST'
export const QUESTION_CREATE = 'QUESTION_CREATE'
export const QUESTION = 'QUESTION'
export const QUESTION_DELETE = 'QUESTION_DELETE'
export const QUESTION_ALL = 'QUESTION_ALL'
export const QUESTION_TYPES = 'QUESTION_TYPES'

export default {
  [QUESTION_LIST]: createAction(QUESTION_LIST),
  [QUESTION_CREATE]: createAction(QUESTION_CREATE),
  [QUESTION]: createAction(QUESTION),
  [QUESTION_DELETE]: createAction(QUESTION_DELETE),
  [QUESTION_ALL]: createAction(QUESTION_ALL),
  [QUESTION_TYPES]: createAction(QUESTION_TYPES),
}