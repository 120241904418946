import actionTypes, { LESSON_DELETE, LESSON, LESSON_ALL, LESSON_CREATE, LESSON_LIST } from '../constants/actionTypes'

export const lesson = payload => ({
  type: actionTypes[LESSON].pending,
  payload,
})

export const lessonClear = () => ({
  type: actionTypes[LESSON].clear,
})

export const lessonCreate = payload => ({
  type: actionTypes[LESSON_CREATE].pending,
  payload,
})

export const lessonDelete = payload => ({
  type: actionTypes[LESSON_DELETE].pending,
  payload,
})

export const lessonList = payload => ({
  type: actionTypes[LESSON_LIST].pending,
  payload,
})

export const lessonListClear = () => ({
  type: actionTypes[LESSON_LIST].clear,
})

export const lessonAll = payload => ({
  type: actionTypes[LESSON_ALL].pending,
  payload,
})

export const lessonAllClear = () => ({
  type: actionTypes[LESSON_ALL].clear,
})
