function createCSV(csv, filename) {
  const blob = new Blob([csv], { type: 'text/csv' })
  const anchor = document.createElement('a')
  anchor.download = filename
  anchor.href = (window.webkitURL || window.URL).createObjectURL(blob)
  anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':')
  anchor.click()
}

export default createCSV
