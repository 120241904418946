import { getInstance } from '../../../helpers/httpClient'
import * as API from '../constants/api'
import { dissoc, prop } from 'ramda/es'

export const account = data =>
  getInstance().get(`${API.ACCOUNT}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const accountCreate = data => getInstance().post(API.ACCOUNT, data)

export const accountDelete = data => getInstance().delete(`${API.ACCOUNT}${prop('id', data)}`)

export const accountList = data =>
  getInstance().get(API.ACCOUNT, {
    params: data,
  })

export const accountAll = data =>
  getInstance().get(API.ACCOUNT_ALL, {
    params: data,
  })

export const accountPasswordReset = data => getInstance().put(API.ACCOUNT_PASSWORD_RESET, data)

export const accountRequiredAction = data => getInstance().put(API.ACCOUNT_REQUIRED_ACTION, data)

export const accountProfilePic = data => getInstance().put(API.ACCOUNT_PROFILE_PIC, data)

export const accountProfileUpdate = data => getInstance().put(API.ACCOUNT_PROFILE_UPDATE, data)

export const accountUpdatePassword = data => getInstance().put(API.ACCOUNT_UPDATE_PASSWORD, data)

export const accountActivate = data => getInstance().put(API.ACCOUNT_ACTIVATE, data)

export const accountDeactivate = data => getInstance().delete(API.ACCOUNT_DEACTIVATE, data)
