import { getInstance } from '@helpers/httpClient'
import { dissoc, prop, propOr } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const cclsModule = data =>
  instance.get(`${API.CCLS_MODULE}${propOr('', 'id', data)}`, {
    params: dissoc('id', data),
  })

export const modulesFilter = data =>
  instance.get(API.CCLS_MODULE, {
    params: data,
  })

export const cclsModuleCreate = data => instance.post(API.CCLS_MODULE, data)

export const cclsModuleUpdate = data => instance.put(`${API.CCLS_MODULE}${prop('id', data)}`, dissoc('id', data))

export const cclsModuleTeacherUpdate = data => instance.post(API.CCLS_MODULE_TEACHER, data)

export const cclsModuleDelete = data => instance.delete(`${API.CCLS_MODULE}${prop('id', data)}`)

export const cclsModuleList = data =>
  instance.get(API.CCLS_MODULE, {
    params: data,
  })

export const cclsModuleAll = data =>
  instance.get(API.CCLS_MODULE_ALL, {
    params: data,
  })

export const lmsModuleAll = data =>
  instance.get(API.LMS_CCLS_MODULE_ALL, {
    params: data,
  })
