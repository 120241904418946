import {
  LAYOUT_CONTENT_WIDTH,
  LAYOUT_MENU_COLLAPSED,
  LAYOUT_MENU_HIDDEN,
  LAYOUT_RTL,
  LAYOUT_SKIN,
  LAYOUT_MENU_DIRECTION,
} from './actionTypes'
// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  menuDirection: themeConfig.layout.type,
}

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LAYOUT_CONTENT_WIDTH:
      return { ...state, contentWidth: action.value }
    case LAYOUT_MENU_COLLAPSED:
      window.localStorage.setItem('menuCollapsed', action.value)
      return { ...state, menuCollapsed: action.value }
    case LAYOUT_MENU_HIDDEN:
      return { ...state, menuHidden: action.value }
    case LAYOUT_RTL:
      return { ...state, isRTL: action.value }
    case LAYOUT_SKIN:
      return { ...state, skin: action.payload }
    case LAYOUT_MENU_DIRECTION :
      themeConfig.layout.type = action.payload
      return { ...state, menuDirection: action.payload }
    default:
      return state
  }
}

export default layoutReducer
