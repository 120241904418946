/* eslint-disable @typescript-eslint/no-var-requires */
// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: 'ISFT Exam Platform',
    appNameShort: 'ISFT',
    appLogo: require('@src/assets/images/logo/logo-black.svg').default,
    appLogoLight: require('@src/assets/images/logo/logo-black.svg').default,
    // appLogoImage: require('@src/assets/images/logo/udea-main-logo-en.svg').default,
    appLogoImage: require('@src/assets/images/logo/logo-black.svg').default,
    // appLogo: require('@src/assets/images/logo/udea-main-logo-en.svg').default,
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'boxed', // full, boxed
    menu: {
      isHidden: true,
      isCollapsed: true,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'sticky', // static , sticky , floating, hidden
      backgroundColor: 'white', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'hidden', // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
  },
  loader: {
    color: '#f8f8f8',
    colorDark: '#161d31',
  },
}

export default themeConfig
