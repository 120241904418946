import { createAction } from '../../../helpers/createAction'

export const STUDENT_ASSESSMENT = 'STUDENT_ASSESSMENT'
export const STUDENT_ASSESSMENT_SAVE = 'STUDENT_ASSESSMENT_SAVE'
export const STUDENT_ASSESSMENT_SUBMIT = 'STUDENT_ASSESSMENT_SUBMIT'
export const STUDENT_ASSESSMENT_SELECT = 'STUDENT_ASSESSMENT_SELECT'

export default {
  [STUDENT_ASSESSMENT]: createAction(STUDENT_ASSESSMENT),
  [STUDENT_ASSESSMENT_SAVE]: createAction(STUDENT_ASSESSMENT_SAVE),
  [STUDENT_ASSESSMENT_SUBMIT]: createAction(STUDENT_ASSESSMENT_SUBMIT),
  [STUDENT_ASSESSMENT_SELECT]: createAction(STUDENT_ASSESSMENT_SELECT),
}
