import { path } from 'ramda/es'
import { createSelector } from 'reselect'
import {
  GLOBAL_STATE,
  TEACHER,
  TEACHER_DASHBOARD,
  TEACHER_ACTION_MADE,
  TEACHER_ALL,
} from '../constants/state'


export const teacherSelector = createSelector(path([GLOBAL_STATE, TEACHER]), data => data)
export const teacherDashboardSelector = createSelector(path([GLOBAL_STATE, TEACHER_DASHBOARD]), data => data)
export const teacherActionMadeSelector = createSelector(path([GLOBAL_STATE, TEACHER_ACTION_MADE]), data => data)
export const teacherAllSelector = createSelector(path([GLOBAL_STATE, TEACHER_ALL]), data => data)

export const teacherSelectors = {
  teacherSelector,
  teacherDashboardSelector,
  teacherActionMadeSelector,
  teacherAllSelector,
}
