import { createAction } from '../../../helpers/createAction'

export const QUESTIONS_BANK_LIST = 'QUESTIONS_BANK_LIST'
export const QUESTIONS_BANK_CREATE = 'QUESTIONS_BANK_CREATE'
export const QUESTIONS_BANK = 'QUESTIONS_BANK'
export const QUESTIONS_BANK_DELETE = 'QUESTIONS_BANK_DELETE'
export const QUESTIONS_BANK_ALL = 'QUESTIONS_BANK_ALL'
export const QUESTIONS_BANK_ADD_TEACHERS = 'QUESTIONS_BANK_ADD_TEACHERS'
export const QUESTIONS_BANK_REMOVE_TEACHERS = 'QUESTIONS_BANK_REMOVE_TEACHERS'

export default {
  [QUESTIONS_BANK_LIST]: createAction(QUESTIONS_BANK_LIST),
  [QUESTIONS_BANK_CREATE]: createAction(QUESTIONS_BANK_CREATE),
  [QUESTIONS_BANK]: createAction(QUESTIONS_BANK),
  [QUESTIONS_BANK_DELETE]: createAction(QUESTIONS_BANK_DELETE),
  [QUESTIONS_BANK_ALL]: createAction(QUESTIONS_BANK_ALL),
  [QUESTIONS_BANK_ADD_TEACHERS]: createAction(QUESTIONS_BANK_ADD_TEACHERS),
  [QUESTIONS_BANK_REMOVE_TEACHERS]: createAction(QUESTIONS_BANK_REMOVE_TEACHERS),
}