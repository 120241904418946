import { getInstance } from '../../../helpers/httpClient'
import * as API from '../constants/api'
import { dissoc, prop } from 'ramda/es'

export const teacherList = data => getInstance().get(API.TEACHER, { params: data })
export const teacher = data => getInstance().get(`${API.TEACHER}/${prop('id', data)}`, { params: dissoc('id', data) })
export const teacherDelete = data => getInstance().delete(`${API.TEACHER}/${prop('id', data)}`)
export const teacherActionMade = data => getInstance().post(API.TEACHER_ACTION_MADE, data)
export const teacherAll = data => getInstance().get(API.TEACHER_ALL, { params: data })

export const teacherDashboard = data => getInstance().get(API.TEACHER_DASHBOARD, data)
