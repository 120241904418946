import * as GLOBAL_STATE from '@constants/state'
import { path, prop } from 'ramda'
import { createSelector } from 'reselect'

import { RootState } from './store'

export * from '@module/Auth/redux/selector'
export * from '@module/Account/redux/selector'
export * from '@module/Roles/redux/selector'
export * from '@module/Lesson/redux/selector'
export * from '@module/Course/activate/redux/selector'
export * from '@module/Groups/redux/selector'
export * from '@module/Modules/activate/redux/selector'
export * from '@module/Modules/create/redux/selector'
export * from '@module/Course/create/redux/selector'
export * from '@module/Student/redux/selector'
export * from '@module/Teacher/redux/selector'
export * from '@module/AcademicYears/redux/selector'
export * from '@module/Exam/redux/selector'
export * from '@module/TeachingGroup/redux/selector'
export * from '@module/Assessment/redux/selector'
export * from '@module/QuestionsBank/redux/selector'
export * from '@module/Question/redux/selector'
export * from '@module/Faculty/redux/selectors'
export * from '@module/Types/redux/selector'
export * from '@module/Subject/redux/selector'
export * from './navbar/selectors'

export { selectors as excelReportSelectors } from '../module/ExcelReport/redux/reduxEssentials'

export const skinSelector = createSelector(path([GLOBAL_STATE.LAYOUT, 'skin']), data => {
  try {
    return data || JSON.parse(localStorage.getItem('skin') || JSON.stringify('light'))
  } catch (error) {}
})

export const routerSelector = createSelector(
  (store: RootState) => ({
    location: store.router.location,
    pathname: prop('pathname', store.router.location),
    query: prop('query', store.router.location),
    state: prop('state', store.router.location),
  }),
  data => data
)

export const querySearchSelector = createSelector(routerSelector, selected => selected.query.search)

export const querySizeSelector = createSelector(routerSelector, selected => selected.query.size)

export const queryPageSelector = createSelector(routerSelector, selected => selected.query.page)
