import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '../../../../helpers/createState'

const reducer = combineReducers({
  [STATE.COURSE_LIST]: createState(actionTypes.COURSE_LIST),
  [STATE.COURSE_ALL]: createState(actionTypes.COURSE_ALL),
  [STATE.COURSE]: createState(actionTypes.COURSE),
  [STATE.COURSE_CREATE]: createState(actionTypes.COURSE_CREATE),
})

export const COURSE_CREATE = STATE.GLOBAL_STATE

export default reducer
