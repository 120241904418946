import { createAction } from '../../../helpers/createAction'

export const TEACHER = 'TEACHER'
export const TEACHER_DASHBOARD = 'TEACHER_DASHBOARD'
export const TEACHER_ACTION_MADE = 'TEACHER_ACTION_MADE'
export const TEACHER_ALL = 'TEACHER_ALL'

export default {
  [TEACHER]: createAction(TEACHER),
  [TEACHER_DASHBOARD]: createAction(TEACHER_DASHBOARD),
  [TEACHER_ACTION_MADE]: createAction(TEACHER_ACTION_MADE),
  [TEACHER_ALL]: createAction(TEACHER_ALL),
}
