import { combineReducers } from 'redux'

import { createState } from '../../../helpers/createState'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.ASSESSMENT_LIST]: createState(actionTypes.ASSESSMENT_LIST),
  [STATE.ASSESSMENT_CREATE]: createState(actionTypes.ASSESSMENT_CREATE),
  [STATE.ASSESSMENT_DELETE]: createState(actionTypes.ASSESSMENT_DELETE),
  [STATE.ASSESSMENT]: createState(actionTypes.ASSESSMENT),
  [STATE.ASSESSMENT_ALL]: createState(actionTypes.ASSESSMENT_ALL),
  [STATE.ASSESSMENT_TYPES]: createState(actionTypes.ASSESSMENT_TYPES),
  [STATE.ASSESSMENT_RESULTS]: createState(actionTypes.ASSESSMENT_RESULTS),
  [STATE.ASSESSMENT_IMPORT_PAPER_BASED]: createState(actionTypes.ASSESSMENT_IMPORT_PAPER_BASED),
  [STATE.ASSESSMENT_GROUPS_IMPORT_BY_STUDENT]: createState(actionTypes.ASSESSMENT_GROUPS_IMPORT_BY_STUDENT),
})

export const ASSESSMENT = STATE.GLOBAL_STATE

export default reducer
