import { dissoc, prop } from 'ramda/es'
import { getInstance } from '../../../helpers/httpClient'
import * as API from '../constants/api'

export const questionsBankList = data => getInstance().get(API.QUESTIONS_BANK, {
  params: data,
})

export const questionsBankCreate = data => getInstance().post(API.QUESTIONS_BANK, data)

export const questionsBankDelete = data => getInstance().delete(`${API.QUESTIONS_BANK}${prop('id', data)}`)

export const questionsBankGetById = data => getInstance().get(`${API.QUESTIONS_BANK}${prop('id', data)}`, {
  params: dissoc('id', data),
})

export const questionsBankAll = data => getInstance().get(API.QUESTIONS_BANK_ALL, {
  params: data,
})

export const questionsBankAddTeachers = data => getInstance().post(API.QUESTIONS_BANK_ADD_TEACHERS, data)

export const questionsBankRemoveTeachers = data => getInstance().post(API.QUESTIONS_BANK_REMOVE_TEACHERS, data)