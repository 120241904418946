import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '../../../helpers/createState'

const reducer = combineReducers({
  [STATE.LESSON_LIST]: createState(actionTypes.LESSON_LIST),
  [STATE.LESSON_CREATE]: createState(actionTypes.LESSON_CREATE),
  [STATE.LESSON_ALL]: createState(actionTypes.LESSON_ALL),
  [STATE.LESSON]: createState(actionTypes.LESSON),
})

export const LESSON = STATE.GLOBAL_STATE

export default reducer
