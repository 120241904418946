import { combineReducers } from 'redux'

import { createState } from '../../../helpers/createState'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'

const reducer = combineReducers({
  [STATE.STUDENT_ASSESSMENT]: createState(actionTypes.STUDENT_ASSESSMENT_SELECT),
  [STATE.STUDENT_ASSESSMENT]: createState(actionTypes.STUDENT_ASSESSMENT),
  [STATE.STUDENT_ASSESSMENT_SAVE]: createState(actionTypes.STUDENT_ASSESSMENT_SAVE),
  [STATE.STUDENT_ASSESSMENT_SUBMIT]: createState(actionTypes.STUDENT_ASSESSMENT_SUBMIT),
})

export const EXAM = STATE.GLOBAL_STATE

export default reducer
