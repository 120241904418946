export const SUPER_ADMIN = 'SUPER_ADMIN'
export const ADMIN = 'ADMIN'
export const DIRECTOR = 'DIRECTOR'
export const BACHELOR_STUDENT = 'BACHELOR_STUDENT'
export const STAFF = 'STAFF'
export const SYSTEM_MODERATOR = 'SYSTEM_MODERATOR'
export const QUALITY_ASSURANCE = 'QUALITY_ASSURANCE'
export const TEACHER = 'TEACHER'
export const STUDENT = 'STUDENT'
export const MODULE_LEADER = 'MODULE_LEADER'
export const ACADEMIC_MENTOR = 'ACADEMIC_MENTOR'
export const REGISTRAR_HEAD = 'REGISTRAR_HEAD'
export const REGISTRAR_OFFICER = 'REGISTRAR_OFFICER'
export const PROGRAMME_MANAGER = 'PROGRAMME_MANAGER'
export const AR_COMMITTEE = 'AR_COMMITTEE'
export const EXTRACURRICULAR_ACTIVITY_MANAGER = 'EXTRACURRICULAR_ACTIVITY_MANAGER'
export const MODERATOR_VIEWER = 'MODERATOR_VIEWER'
export const TEACHING_AND_LEARNING_OFFICER = 'TEACHING_AND_LEARNING_OFFICER'

export const TEST_STUDENT = 'TEST_STUDENT'
export const DEMO_ADMIN = 'DEMO_ADMIN'
export const MS_APPLICANT = 'MS_APPLICANT'
