import { createAction } from '../../../../helpers/createAction'

export const COURSE = 'COURSE'
export const COURSE_CREATE = 'COURSE_CREATE'
export const COURSE_DELETE = 'COURSE_DELETE'
export const COURSE_LIST = 'COURSE_LIST'
export const COURSE_ALL = 'COURSE_ALL'

export default {
  [COURSE]: createAction(COURSE),
  [COURSE_CREATE]: createAction(COURSE_CREATE),
  [COURSE_DELETE]: createAction(COURSE_DELETE),
  [COURSE_LIST]: createAction(COURSE_LIST),
  [COURSE_ALL]: createAction(COURSE_ALL),
}
