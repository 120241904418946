import {
  NAVBAR_GET_BOOKMARKS,
  NAVBAR_UPDATE_BOOKMARKED,
  NAVBAR_SEARCH_QUERY,
  NAVBAR_PARENT,
  NAVBAR_PARENT_CLEAR,
} from './actionTypes'
import { dissoc } from 'ramda/es'

// ** Initial State
const initialState = {
  suggestions: [],
  bookmarks: [],
  query: '',
}

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case NAVBAR_SEARCH_QUERY:
      return { ...state, query: action.val }
    case NAVBAR_GET_BOOKMARKS:
      return { ...state, suggestions: action.data, bookmarks: action.bookmarks }
    case NAVBAR_UPDATE_BOOKMARKED:
      let objectToUpdate

      // ** find & update object
      state.suggestions.find(item => {
        if (item.id === action.id) {
          item.isBookmarked = !item.isBookmarked
          objectToUpdate = item
        }
      })

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex(x => x.id === action.id)

      if (bookmarkIndex === -1) {
        state.bookmarks.push(objectToUpdate)
      } else {
        state.bookmarks.splice(bookmarkIndex, 1)
      }

      return { ...state }
    case NAVBAR_PARENT :
      return {
        ...state,
        parent: action.payload,
      }
    case NAVBAR_PARENT_CLEAR :
      return {
        ...dissoc('parent', state),
      }
    default:
      return state
  }
}

export default navbarReducer
