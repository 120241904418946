import actionTypes, {
  TEACHING_GROUP_ALL,
  TEACHING_GROUP_TEACHERS,
  TEACHING_GROUP,
  TEACHING_GROUP_GRADES,
  TEACHING_GROUP_STUDENTS,
  TEACHING_GROUP_TYPES,
  TEACHING_GROUP_LIST,
  TEACHING_GROUP_CREATE,
  TEACHING_GROUP_DELETE,
} from '../constants/actionTypes'

export const teachingGroup = payload => ({
  type: actionTypes[TEACHING_GROUP].pending,
  payload,
})

export const teachingGroupClear = () => ({
  type: actionTypes[TEACHING_GROUP].clear,
})

export const teachingGroupList = payload => ({
  type: actionTypes[TEACHING_GROUP_LIST].pending,
  payload,
})

export const teachingGroupListClear = () => ({
  type: actionTypes[TEACHING_GROUP_LIST].clear,
})

export const teachingGroupAll = payload => ({
  type: actionTypes[TEACHING_GROUP_ALL].pending,
  payload,
})

export const teachingGroupAllClear = () => ({
  type: actionTypes[TEACHING_GROUP_ALL].clear,
})

export const teachingGroupGrades = payload => ({
  type: actionTypes[TEACHING_GROUP_GRADES].pending,
  payload,
})

export const teachingGroupGradesClear = () => ({
  type: actionTypes[TEACHING_GROUP_GRADES].clear,
})

export const teachingGroupTypes = payload => ({
  type: actionTypes[TEACHING_GROUP_TYPES].pending,
  payload,
})

export const teachingGroupTypesClear = () => ({
  type: actionTypes[TEACHING_GROUP_TYPES].clear,
})

export const teachingGroupStudents = payload => ({
  type: actionTypes[TEACHING_GROUP_STUDENTS].pending,
  payload,
})

export const teachingGroupStudentsClear = () => ({
  type: actionTypes[TEACHING_GROUP_STUDENTS].clear,
})

export const teachingGroupTeachers = payload => ({
  type: actionTypes[TEACHING_GROUP_TEACHERS].pending,
  payload,
})

export const teachingGroupTeachersClear = () => ({
  type: actionTypes[TEACHING_GROUP_TEACHERS].clear,
})

export const teachingGroupCreate = payload => ({
  type: actionTypes[TEACHING_GROUP_CREATE].pending,
  payload,
})

export const teachingGroupDelete = payload => ({
  type: actionTypes[TEACHING_GROUP_DELETE].pending,
  payload,
})

