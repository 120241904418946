import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '../../../../helpers/createState'

const reducer = combineReducers({
  [STATE.CCLS_MODULE]: createState(actionTypes.CCLS_MODULE),
  [STATE.CCLS_MODULE_CREATE]: createState(actionTypes.CCLS_MODULE_CREATE),
  [STATE.CCLS_MODULE_LIST]: createState(actionTypes.CCLS_MODULE_LIST),
  [STATE.CCLS_MODULE_ALL]: createState(actionTypes.CCLS_MODULE_ALL),
  [STATE.CCLS_MODULES_FILTER]: createState(actionTypes.CCLS_MODULES_FILTER),
})

export const CCLS_MODULE = STATE.GLOBAL_STATE

export default reducer
