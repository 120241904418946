import { getHost, getInstance } from '@helpers/httpClient'
import { API_URL } from '@src/constants/api'
import { getToken } from '@src/helpers/storageToken'
import axios from 'axios'
import { dissoc, prop } from 'ramda'

import * as API from '../constants/api'

const instance = getInstance()

export const questionList = data =>
  instance.get(API.QUESTION, {
    params: data,
  })

export const questionCreate = data => instance.post(API.QUESTION, data)

export const questionDelete = data => {
  const query = prop('lesson_id', data)
    ? `${API.QUESTION}${prop('id', data)}?lesson_id=${prop('lesson_id', data)}`
    : `${API.QUESTION}${prop('id', data)}`
  return instance.delete(query)
}

export const questionGetById = data =>
  instance.get(`${API.QUESTION}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const questionAll = data =>
  instance.get(API.QUESTION_ALL, {
    params: data,
  })

export const questionTypes = data =>
  instance.get(API.QUESTION_TYPES, {
    params: data,
  })

export const questionImport = data =>
  axios.request({
    method: 'POST',
    baseURL: getHost(),
    url: `${API.QUESTION_IMPORT}`,
    data,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'multipart/form-data',
    },
  })
