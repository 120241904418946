import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '../../../helpers/createState'

const reducer = combineReducers({
  [STATE.QUESTION_LIST]: createState(actionTypes.QUESTION_LIST),
  [STATE.QUESTION_ALL]: createState(actionTypes.QUESTION_ALL),
  [STATE.QUESTION_CREATE]: createState(actionTypes.QUESTION_CREATE),
  [STATE.QUESTION_DELETE]: createState(actionTypes.QUESTION_DELETE),
  [STATE.QUESTION]: createState(actionTypes.QUESTION),
  [STATE.QUESTION_TYPES]: createState(actionTypes.QUESTION_TYPES),
})

export const QUESTION = STATE.GLOBAL_STATE

export default reducer