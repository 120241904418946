import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { toastError } from '@src/helpers/toastError'
import { PayloadCreatorParams } from '@src/redux/types'
import { omit } from 'ramda'

import * as api from '../api'
import {
  GROUP_ALL,
  GROUP_ASSIGN,
  GROUP_ASSIGNED_LIST,
  GROUP_DELETE,
  GROUP_EDIT,
  GROUP_EXPORT,
  GROUP_LIST,
  GROUP_SHUFFLE,
  GROUP_STUDENT_TRANSFER,
} from '../constants/actionTypes'
import * as API from '../constants/api'

// export const groupExport = createAsyncThunk(
//   GROUP_EXPORT,
//   async (params: PayloadCreatorParams<`${typeof API.GROUP_EXPORT}`, 'get'>, { rejectWithValue }) => {
//     try {
//       const response = await api.groupExport(
//         omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
//         params?.config
//       )
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

export const groupAll = createAsyncThunk(
  GROUP_ALL,
  async (params: PayloadCreatorParams<`${typeof API.GROUP_ALL}`, 'get'>, { rejectWithValue }) => {
    try {
      const response = await api.groupAll(
        omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
        params?.config
      )
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      const error = toastError(e)
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

// export const groupList = createAsyncThunk(
//   GROUP_LIST,
//   async (params: PayloadCreatorParams<`${typeof API.GROUP}`, 'get'>, { rejectWithValue }) => {
//     try {
//       const response = await api.groupList(
//         omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
//         params?.config
//       )
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

// export const groupShuffle = createAsyncThunk(
//   GROUP_SHUFFLE,
//   async (params: PayloadCreatorParams<`${typeof API.GROUP_SHUFFLE}`, 'post'>, { rejectWithValue }) => {
//     try {
//       const response = await api.groupShuffle(
//         omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
//         params?.config
//       )
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

// export const groupEdit = createAsyncThunk(
//   GROUP_EDIT,
//   async (params: PayloadCreatorParams<`${typeof API.GROUP}`, 'post'>, { rejectWithValue }) => {
//     try {
//       const response = await api.groupEdit(
//         omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
//         params?.config
//       )
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

// export const groupDelete = createAsyncThunk(
//   GROUP_DELETE,
//   async (params: PayloadCreatorParams<`${typeof API.GROUP}{id}`, 'delete'>, { rejectWithValue }) => {
//     try {
//       const response = await api.groupDelete(
//         omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
//         params?.config
//       )
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

// export const groupStudentTransfer = createAsyncThunk(
//   GROUP_STUDENT_TRANSFER,
//   async (params: PayloadCreatorParams<`${typeof API.GROUP_STUDENT_TRANSFER}`, 'post'>, { rejectWithValue }) => {
//     try {
//       const response = await api.groupStudentTransfer(
//         omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
//         params?.config
//       )
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

// export const groupAssign = createAsyncThunk(
//   GROUP_ASSIGN,
//   async (params: PayloadCreatorParams<`${typeof API.GROUP_ASSIGN}`, 'post'>, { rejectWithValue }) => {
//     try {
//       const response = await api.groupAssign(
//         omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
//         params?.config
//       )
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

// export const groupAssignedList = createAsyncThunk(
//   GROUP_ASSIGNED_LIST,
//   async (params: PayloadCreatorParams<`${typeof API.GROUP_ASSIGN}`, 'get'>, { rejectWithValue }) => {
//     try {
//       const response = await api.groupAssignedList(
//         omit(['onFulfilled', 'onRejected', 'onSettled', 'config'], params),
//         params?.config
//       )
//       params?.onFulfilled && params.onFulfilled(response.data)
//       return response.data
//     } catch (e) {
//       const error = toastError(e)
//       params?.onRejected && params.onRejected({ error })
//       return rejectWithValue(error)
//     } finally {
//       params?.onSettled && params.onSettled()
//     }
//   }
// )

export const groupAllClear = createAction(`${GROUP_ALL}/clear`)

export const groupListClear = createAction(`${GROUP_LIST}/clear`)

export const groupShuffleClear = createAction(`${GROUP_SHUFFLE}/clear`)

export const groupStudentTransferClear = createAction(`${GROUP_STUDENT_TRANSFER}/clear`)

export const groupAssignClear = createAction(`${GROUP_ASSIGN}/clear`)

export const groupAssignedListClear = createAction(`${GROUP_ASSIGNED_LIST}/clear`)
