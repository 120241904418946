import actionTypes, {
  MODULES,
  MODULES_ALL,
  MODULES_LIST,
  MODULES_CREATE,
  MODULES_DELETE,
} from '../constants/actionTypes'

export const modules = payload => ({
  type: actionTypes[MODULES].pending,
  payload,
})

export const modulesCreate = payload => ({
  type: actionTypes[MODULES_CREATE].pending,
  payload,
})

export const modulesDelete = payload => ({
  type: actionTypes[MODULES_DELETE].pending,
  payload,
})

export const modulesListClear = () => ({
  type: actionTypes[MODULES_LIST].clear,
})

export const modulesList = payload => ({
  type: actionTypes[MODULES_LIST].pending,
  payload,
})

export const modulesAll = payload => ({
  type: actionTypes[MODULES_ALL].pending,
  payload,
})

export const modulesAllClear = () => ({
  type: actionTypes[MODULES_ALL].clear,
})

export const modulesRejected = payload => ({
  type: actionTypes[MODULES_LIST].rejected,
  payload,
})
