import { propOr } from 'ramda'
import { path } from 'ramda/es'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  TEACHING_GROUP,
  TEACHING_GROUP_ALL,
  TEACHING_GROUP_CREATE,
  TEACHING_GROUP_GRADES,
  TEACHING_GROUP_LIST,
  TEACHING_GROUP_STUDENTS,
  TEACHING_GROUP_TEACHERS,
  TEACHING_GROUP_TYPES,
} from '../constants/state'

export const teachingGroupSelector = createSelector(path([GLOBAL_STATE, TEACHING_GROUP]), data => data)

export const teachingGroupCreateSelector = createSelector(path([GLOBAL_STATE, TEACHING_GROUP_CREATE]), data => data)

export const teachingGroupListSelector = createSelector(path([GLOBAL_STATE, TEACHING_GROUP_LIST]), data => data)

export const teachingGroupAllSelector = createSelector(path([GLOBAL_STATE, TEACHING_GROUP_ALL]), data => data)

export const teachingGroupAllOptions = createSelector(path([GLOBAL_STATE, TEACHING_GROUP_ALL]), selected => {
  return {
    loading: selected.loading,
    error: selected.error,
    options: Array.isArray(selected.data)
      ? selected.data.map(group => ({ label: propOr('', 'title', group), value: group.id }))
      : [],
  }
})

export const teachingGroupTeachersSelector = createSelector(path([GLOBAL_STATE, TEACHING_GROUP_TEACHERS]), data => data)

export const teachingGroupStudentsSelector = createSelector(path([GLOBAL_STATE, TEACHING_GROUP_STUDENTS]), data => data)

export const teachingGroupGradeOptionsSelector = createSelector(
  path([GLOBAL_STATE, TEACHING_GROUP_GRADES]),
  selected => {
    return {
      loading: selected.loading,
      error: selected.error,
      options: Array.isArray(selected.data) ? selected.data.map(type => ({ label: type, value: type })) : [],
    }
  }
)

export const teachingGroupTypesSelector = createSelector(path([GLOBAL_STATE, TEACHING_GROUP_TYPES]), data => data)
