export const ACCOUNT = '/account/'
export const ACCOUNT_ALL = `${ACCOUNT}all`
export const ACCOUNT_PASSWORD_RESET = `${ACCOUNT}password/reset`
export const ACCOUNT_REQUIRED_ACTION = `${ACCOUNT}required_action/`
export const ACCOUNT_PROFILE_PIC = `${ACCOUNT}profile_pic/`
export const ACCOUNT_PROFILE_UPDATE = `${ACCOUNT}update/profile/`

export const ACCOUNT_UPDATE_PASSWORD = `${ACCOUNT}update/password`
export const ACCOUNT_ACTIVATE = `${ACCOUNT}activate`
export const ACCOUNT_DEACTIVATE = `${ACCOUNT}delete`
