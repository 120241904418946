import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '../../../helpers/createState'

const reducer = combineReducers({
  [STATE.QUESTIONS_BANK_LIST]: createState(actionTypes.QUESTIONS_BANK_LIST),
  [STATE.QUESTIONS_BANK_ALL]: createState(actionTypes.QUESTIONS_BANK_ALL),
  [STATE.QUESTIONS_BANK_CREATE]: createState(actionTypes.QUESTIONS_BANK_CREATE),
  [STATE.QUESTIONS_BANK_DELETE]: createState(actionTypes.QUESTIONS_BANK_DELETE),
  [STATE.QUESTIONS_BANK]: createState(actionTypes.QUESTIONS_BANK),
  [STATE.QUESTIONS_BANK_ADD_TEACHERS]: createState(actionTypes.QUESTIONS_BANK_ADD_TEACHERS),
  [STATE.QUESTIONS_BANK_REMOVE_TEACHERS]: createState(actionTypes.QUESTIONS_BANK_REMOVE_TEACHERS),
})

export const QUESTIONS_BANK = STATE.GLOBAL_STATE

export default reducer