export const STUDENT = '/student/'
export const STUDENT_STATUS = `${STUDENT}status`
export const STUDENT_ALL = `${STUDENT}all`
export const STUDENT_GUARD = `${STUDENT}guard/`
export const STUDENT_USERNAME = `${STUDENT}username/`
export const STUDENT_ACHIEVEMENT_RECORDS = `${STUDENT}ar_records/`
export const STUDENT_PERSONAL_INFO = `${STUDENT}personal_info`
export const STUDENT_CONTACT_INFO = `${STUDENT}contact_info`
export const GENERATE_TRANSCRIPT = `${STUDENT}transcript`
export const ACHIEVEMENT_RECORDS_STUDENT = `achievement_records/student/`
export const STUDENT_DASHBOARD = `/dashboard/student`