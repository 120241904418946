import { createAction } from '../../../helpers/createAction'

export const STUDENT = 'STUDENT'
export const STUDENT_STATUS_UPDATE = 'STUDENT_STATUS_UPDATE'
export const STUDENT_DELETE = 'STUDENT_DELETE'
export const STUDENT_LIST = 'STUDENT_LIST'
export const STUDENT_ALL = 'STUDENT_ALL'
export const STUDENT_GUARD_CREATE = 'STUDENT_GUARD_CREATE'
export const STUDENT_GUARD_LIST = 'STUDENT_GUARD_LIST'
export const STUDENT_USERNAME = 'STUDENT_USERNAME'
export const STUDENT_PERSONAL_INFO = 'STUDENT_PERSONAL_INFO'
export const STUDENT_CONTACT_INFO = 'STUDENT_CONTACT_INFO'
export const GENERATE_TRANSCRIPT = 'GENERATE_TRANSCRIPT'
export const STUDENT_ACHIEVEMENT_RECORDS = 'STUDENT_ACHIEVEMENT_RECORDS'
export const STUDENT_DASHBOARD = 'STUDENT_DASHBOARD'

export default {
  [STUDENT]: createAction(STUDENT),
  [STUDENT_DELETE]: createAction(STUDENT_DELETE),
  [STUDENT_STATUS_UPDATE]: createAction(STUDENT_STATUS_UPDATE),
  [STUDENT_LIST]: createAction(STUDENT_LIST),
  [STUDENT_ALL]: createAction(STUDENT_ALL),
  [STUDENT_GUARD_CREATE]: createAction(STUDENT_GUARD_CREATE),
  [STUDENT_GUARD_LIST]: createAction(STUDENT_GUARD_LIST),
  [STUDENT_USERNAME]: createAction(STUDENT_USERNAME),
  [STUDENT_PERSONAL_INFO]: createAction(STUDENT_PERSONAL_INFO),
  [STUDENT_CONTACT_INFO]: createAction(STUDENT_CONTACT_INFO),
  [GENERATE_TRANSCRIPT]: createAction(GENERATE_TRANSCRIPT),
  [STUDENT_ACHIEVEMENT_RECORDS]: createAction(STUDENT_ACHIEVEMENT_RECORDS),
  [STUDENT_DASHBOARD]: createAction(STUDENT_DASHBOARD),
}
