import AcademicYears, { ACADEMIC_YEARS } from '@module/AcademicYears/redux/reducer'
import Account, { ACCOUNT } from '@module/Account/redux/reducer'
import Assessment, { ASSESSMENT } from '@module/Assessment/redux/reducer'
import { AUTH, authReducer } from '@module/Auth/redux/reducer'
import { COURSE_CAMPAIGNS, courseCampaignReducer } from '@module/Course/activate/redux/reducer'
import CourseCreate, { COURSE_CREATE } from '@module/Course/create/redux/reducer'
import Exam, { EXAM } from '@module/Exam/redux/reducer'
import { EXCEL_REPORT, reducer as ExcelReport } from '@module/ExcelReport/redux/reduxEssentials'
import { FACULTY_STATE, facultyReducer } from '@module/Faculty/redux/reducer'
import { GROUPING, groupReducer } from '@module/Groups/redux/reducer'
import Lesson, { LESSON } from '@module/Lesson/redux/reducer'
import CCLSModule, { CCLS_MODULE } from '@module/Modules/activate/redux/reducer'
import Modules, { MODULES } from '@module/Modules/create/redux/reducer'
import Question, { QUESTION } from '@module/Question/redux/reducer'
import QuestionsBank, { QUESTIONS_BANK } from '@module/QuestionsBank/redux/reducer'
import Role, { ROLES } from '@module/Roles/redux/reducer'
import Student, { STUDENT } from '@module/Student/redux/reducer'
import { SUBJECT, subjectReducer } from '@module/Subject/redux/reducer'
import Teacher, { TEACHER } from '@module/Teacher/redux/reducer'
import TeachingGroup, { TEACHING_GROUP } from '@module/TeachingGroup/redux/reducer'
import { TYPE, typesReducer } from '@module/Types/redux/reducer'
import { combineReducers } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'

import layout from './layout/reducer'
import navbar from './navbar/reducer'
import { history } from './store'

const rootReducer = combineReducers({
  router: connectRouter(history),
  navbar,
  layout,
  [AUTH]: authReducer,
  [ACCOUNT]: Account,
  [ROLES]: Role,
  [LESSON]: Lesson,
  [COURSE_CAMPAIGNS]: courseCampaignReducer,
  [CCLS_MODULE]: CCLSModule,
  [COURSE_CREATE]: CourseCreate,
  [GROUPING]: groupReducer,
  [MODULES]: Modules,
  [STUDENT]: Student,
  [TEACHER]: Teacher,
  [ACADEMIC_YEARS]: AcademicYears,
  [EXAM]: Exam,
  [TEACHING_GROUP]: TeachingGroup,
  [ASSESSMENT]: Assessment,
  [QUESTIONS_BANK]: QuestionsBank,
  [QUESTION]: Question,
  [EXCEL_REPORT]: ExcelReport,
  [FACULTY_STATE]: facultyReducer,
  [TYPE]: typesReducer,
  [SUBJECT]: subjectReducer,
})

const reducers = (state, action) => {
  if (action.type === 'RESET_APP') {
    const { router, navbar, layout } = state
    state = { router, navbar, layout }
  }
  return rootReducer(state, action)
}

export default reducers
