import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '../../../helpers/createState'

const reducer = combineReducers({
  [STATE.STUDENT]: createState(actionTypes.STUDENT),
  [STATE.STUDENT_LIST]: createState(actionTypes.STUDENT_LIST),
  [STATE.STUDENT_ALL]: createState(actionTypes.STUDENT_ALL),
  [STATE.STUDENT_USERNAME]: createState(actionTypes.STUDENT_USERNAME),
  [STATE.STUDENT_ACHIEVEMENT_RECORDS]: createState(actionTypes.STUDENT_ACHIEVEMENT_RECORDS),
  [STATE.GENERATE_TRANSCRIPT]: createState(actionTypes.GENERATE_TRANSCRIPT),
  [STATE.STUDENT_GUARD_LIST]: createState(actionTypes.STUDENT_GUARD_LIST),
  [STATE.STUDENT_PERSONAL_INFO_UPDATE]: createState(actionTypes.STUDENT_PERSONAL_INFO),
  [STATE.STUDENT_CONTACT_INFO_UPDATE]: createState(actionTypes.STUDENT_CONTACT_INFO),
  [STATE.STUDENT_DASHBOARD]: createState(actionTypes.STUDENT_DASHBOARD),
})

export const STUDENT = STATE.GLOBAL_STATE

export default reducer
