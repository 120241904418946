import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '../../../helpers/createState'

const reducer = combineReducers({
  [STATE.TEACHER]: createState(actionTypes.TEACHER),
  [STATE.TEACHER_DASHBOARD]: createState(actionTypes.TEACHER_DASHBOARD),
  [STATE.TEACHER_ACTION_MADE]: createState(actionTypes.TEACHER_ACTION_MADE),
  [STATE.TEACHER_ALL]: createState(actionTypes.TEACHER_ALL),
})

export const TEACHER = STATE.GLOBAL_STATE

export default reducer
