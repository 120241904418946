import { pipe, prop, dissoc, map, pathOr } from 'ramda/es'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { jsonToCSV } from 'react-papaparse'
import actionTypes, { COURSE, COURSE_CREATE, COURSE_DELETE, COURSE_LIST, COURSE_ALL } from '../constants/actionTypes'
import * as API from '../../create/api'
import * as actions from '../../../../redux/actions'
import { watchSaga } from '../../../../helpers/customSaga'
import createCSV from '../../../../helpers/createCSV'

function watchCourse() {
  return watchSaga({
    action: actionTypes[COURSE],
    api: API.course,
  })
}

function watchCourseCreate() {
  return watchSaga({
    action: actionTypes[COURSE_CREATE],
    api: API.courseCreate,
  })
}

function* watchCourseCreateFulfilled() {
  yield takeEvery(actionTypes[COURSE_CREATE].fulfilled, function* () {
    yield put(actions.courseList())
  })
}

function watchCourseDelete() {
  return watchSaga({
    action: actionTypes[COURSE_DELETE],
    api: API.courseDelete,
  })
}

function* watchCourseDeleteFulfilled() {
  yield takeEvery(actionTypes[COURSE_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.courseList())
  })
}

function* watchCourseDeleteRejected() {
  yield takeEvery(actionTypes[COURSE_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchCourseList() {
  return watchSaga({
    action: actionTypes[COURSE_LIST],
    api: API.courseList,
  })
}

function* watchCourseAll() {
  yield takeEvery(actionTypes[COURSE_ALL].pending, function* ({ payload }) {
    try {
      const { data } = yield call(API.courseAll, dissoc('csv', payload))

      yield put({
        type: actionTypes[COURSE_ALL].fulfilled,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionTypes[COURSE].rejected,
        payload: error,
      })
    }
  })
}

function* watchCourseAllFulfilled() {
  yield takeEvery(actionTypes[COURSE_ALL].fulfilled, function ({ payload, args }) {
    const csv = prop('csv', args)

    if (csv) {
      const newCSV = pipe(
        map(item => ({
          Title: prop('title', item),
        })),
        item => jsonToCSV(item, { header: true }),
      )(payload || [])

      createCSV(newCSV, 'Course.csv')
    }
  })
}

export function* courseCreateSaga() {
  yield all([
    fork(watchCourse),
    fork(watchCourseCreate),
    fork(watchCourseCreateFulfilled),
    fork(watchCourseDelete),
    fork(watchCourseDeleteFulfilled),
    fork(watchCourseDeleteRejected),
    fork(watchCourseList),
    fork(watchCourseAll),
    fork(watchCourseAllFulfilled),
  ])
}
