import { dissoc, prop } from 'ramda/es'
import { getInstance } from '../../../helpers/httpClient'
import * as API from '../../Roles/constants/api'

export const role = data =>
  getInstance().get(`${API.ROLE}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const roleCreate = data => getInstance().post(API.ROLE, data)

export const roleDelete = data => getInstance().delete(`${API.ROLE}${prop('id', data)}`)

export const roleList = data =>
  getInstance().get(API.ROLE, {
    params: data,
  })

export const roleAll = data =>
  getInstance().get(API.ROLE_ALL, {
    params: data,
  })
