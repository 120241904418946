import { dissoc, prop } from 'ramda/es'

import { getInstance } from '../../../helpers/httpClient'
import * as API from '../constants/api'

const instance = getInstance()

export const assessmentList = data =>
  instance.get(API.ASSESSMENT, {
    params: data,
  })

export const assessmentCreate = data => instance.post(API.ASSESSMENT, data)

export const assessmentDelete = data => instance.delete(`${API.ASSESSMENT}${prop('id', data)}`)

export const assessmentGetById = data =>
  instance.get(`${API.ASSESSMENT}${prop('id', data)}`, {
    params: dissoc('id', data),
  })

export const assessmentAll = data =>
  instance.get(API.ASSESSMENT_ALL, {
    params: data,
  })

export const assessmentTypes = data =>
  instance.get(API.ASSESSMENT_TYPES, {
    params: data,
  })

export const assessmentResults = data =>
  instance.get(API.ASSESSMENT_RESULTS, {
    params: data,
  })

export const assessmentImportPaperBased = data => instance.post(API.ASSESSMENT_IMPORT_PAPER_BASED, data)

export const assessmentGroupsCreate = data => instance.post(API.ASSESSMENT_GROUPS, data)

export const assessmentGroupsImportByStudent = data => instance.post(API.ASSESSMENT_GROUPS_IMPORT_BY_STUDENT, data)
