import { all, fork } from 'redux-saga/effects'

import { WatchSaga, watchSaga } from '../../../helpers/customSaga'
import * as API from '../api'
import actionTypes, { TEACHER, TEACHER_ACTION_MADE, TEACHER_ALL, TEACHER_DASHBOARD } from '../constants/actionTypes'

function watchTeacher() {
  return watchSaga({
    action: actionTypes[TEACHER],
    api: API.teacher,
  })
}

function watchTeacherDashboard() {
  return watchSaga({
    action: actionTypes[TEACHER_DASHBOARD],
    api: API.teacherDashboard,
  })
}

function watchTeacherActionMade() {
  return watchSaga({
    action: actionTypes[TEACHER_ACTION_MADE],
    api: API.teacherActionMade,
  })
}

const teacherAllSaga = new WatchSaga({ actionType: actionTypes[TEACHER_ALL], api: API.teacherAll })

export function* teacherSaga() {
  yield all([
    fork(watchTeacher),
    fork(watchTeacherDashboard),
    fork(watchTeacherActionMade),
    fork(teacherAllSaga.watch),
    fork(teacherAllSaga.watchFulfilled),
    fork(teacherAllSaga.watchRejected),
  ])
}
