import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import { FACULTY, FACULTY_ALL, GLOBAL_STATE } from '../constants/actionTypes'
import * as actions from './actions'
import { defaultState, initialState } from './initialState'

export const FACULTY_STATE = GLOBAL_STATE

const facultySlice = createSlice({
  name: FACULTY_STATE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(actions.facultyAll.pending, state => {
        state[FACULTY_ALL].loading = true
      })
      .addCase(actions.facultyAll.rejected, (state, action) => {
        state[FACULTY_ALL].loading = false
        state[FACULTY_ALL].error = action.payload as AxiosError
      })
      .addCase(actions.facultyAll.fulfilled, (state, action) => {
        state[FACULTY_ALL].loading = false
        state[FACULTY_ALL].error = null
        state[FACULTY_ALL].data = action.payload
      })

    builder.addCase(actions.facultyAllClear, state => {
      state[FACULTY_ALL] = defaultState()
    })
  },
})

export const { reducer: facultyReducer } = facultySlice
