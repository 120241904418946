import { pipe, prop, propOr, filter, find, equals, isEmpty } from 'ramda/es'
import * as USER_ROLES from '../constants/userRoles'
import * as USER_REQUIRED_ROLES from '../constants/userRequiredActions'

export const isUpdatePassword = pipe(
  propOr([], 'required_actions'),
  find(item => equals(USER_REQUIRED_ROLES.UPDATE_PASSWORD, item)),
  Boolean,
)

export const isVerifyEmail = pipe(
  propOr([], 'required_actions'),
  find(item => equals(USER_REQUIRED_ROLES.VERIFY_EMAIL, item)),
  Boolean,
)

export const isEnrollmentRequired = pipe(
  propOr([], 'required_actions'),
  find(item => equals(USER_REQUIRED_ROLES.ENROLLMENT_REQUIRED, item)),
  Boolean,
)

export const isSuperAdmin = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.SUPER_ADMIN, item)),
  Boolean,
)

export const isAdmin = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.ADMIN, item)),
  Boolean,
)

export const isDirector = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.DIRECTOR, item)),
  Boolean,
)

export const isBachelorStudent = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.BACHELOR_STUDENT, item)),
  Boolean,
)

export const hasAssessment = pipe(prop('has_assessment'))

export const isTestStudent = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.TEST_STUDENT, item)),
  Boolean,
)

export const isStaff = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.STAFF, item)),
  Boolean,
)

export const isSystemModerator = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.SYSTEM_MODERATOR, item)),
  Boolean,
)

export const isTeacher = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.TEACHER, item)),
  Boolean,
)

export const isMentor = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.ACADEMIC_MENTOR, item)),
  Boolean,
)

export const isModuleLeader = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.MODULE_LEADER, item)),
  Boolean,
)

export const isAcademicMentor = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.ACADEMIC_MENTOR, item)),
  Boolean,
)

export const isRegisterHead = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.REGISTRAR_HEAD, item)),
  Boolean,
)

export const isRegisterOfficer = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.REGISTRAR_OFFICER, item)),
  Boolean,
)

export const isProgramManager = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.PROGRAMME_MANAGER, item)),
  Boolean,
)

export const isTLOfficer = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.TEACHING_AND_LEARNING_OFFICER, item)),
  Boolean,
)

export const isQualityAssurance = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.QUALITY_ASSURANCE, item)),
  Boolean,
)

export const isExtracurricularActivityManager = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.EXTRACURRICULAR_ACTIVITY_MANAGER, item)),
  Boolean,
)

export const isModeratorViewer = pipe(
  propOr([], 'roles'),
  find(item => equals(USER_ROLES.MODERATOR_VIEWER, item)),
  Boolean,
)

export const hasAnyRole = (userInfo, rolesToCheck = []) => {
  const roles = prop('roles', userInfo)

  if (!roles) return false

  return pipe(
    filter(role => find(item => item === role)(rolesToCheck)),
    item => !isEmpty(item),
  )(roles)
}