import actionTypes, {
  QUESTION,
  QUESTION_DELETE,
  QUESTION_LIST,
  QUESTION_ALL,
  QUESTION_CREATE,
  QUESTION_TYPES,
} from '../constants/actionTypes'

export const question = payload => ({
  type: actionTypes[QUESTION].pending,
  payload,
})

export const questionClear = () => ({
  type: actionTypes[QUESTION].clear,
})

export const questionList = payload => ({
  type: actionTypes[QUESTION_LIST].pending,
  payload,
})

export const questionListClear = () => ({
  type: actionTypes[QUESTION_LIST].clear,
})

export const questionAll = payload => ({
  type: actionTypes[QUESTION_ALL].pending,
  payload,
})

export const questionAllClear = () => ({
  type: actionTypes[QUESTION_ALL].clear,
})

export const questionCreate = payload => ({
  type: actionTypes[QUESTION_CREATE].pending,
  payload,
})

export const questionCreateClear = () => ({
  type: actionTypes[QUESTION_CREATE].clear,
})

export const questionDelete = payload => ({
  type: actionTypes[QUESTION_DELETE].pending,
  payload,
})

export const questionDeleteClear = () => ({
  type: actionTypes[QUESTION_DELETE].clear,
})

export const questionTypes = payload => ({
  type: actionTypes[QUESTION_TYPES].pending,
  payload,
})

export const questionTypesClear = () => ({
  type: actionTypes[QUESTION_TYPES].clear,
})