import { RootState } from '@src/redux/store'
import { prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

import {
  GLOBAL_STATE,
  GROUP_ALL,
  GROUP_ASSIGN,
  GROUP_ASSIGNED_LIST,
  GROUP_EXPORT,
  GROUP_LIST,
  GROUP_SHUFFLE,
  GROUP_STUDENT_TRANSFER,
} from '../constants/actionTypes'

const groupsSelector = (state: RootState) => prop(GLOBAL_STATE, state)

export const groupAllSelector = createSelector(groupsSelector, data => data[GROUP_ALL])

// export const groupListSelector = createSelector(groupsSelector, data => data[GROUP_LIST])

/**
 * Returns students found in group based on "group={id}" query
 */
// export const groupedStudentsSelector = createSelector(
//   (state: RootState) => state,
//   (state: RootState) => {
//     const query = state.router.location.query
//     return query.group
//   },
//   groupAllSelector,
//   (_, groupId, groupAll) => {
//     if (!groupId || !groupAll || !prop('data', groupAll) || !groupAll.data?.groups) return

//     return groupAll.data.groups.find(item => item.group?.id === Number(groupId))?.students
//   }
// )

// export const groupShuffleSelector = createSelector(groupsSelector, data => data[GROUP_SHUFFLE])

// export const groupStudentTransferSelector = createSelector(groupsSelector, data => data[GROUP_STUDENT_TRANSFER])

// export const groupAssignSelector = createSelector(groupsSelector, data => data[GROUP_ASSIGN])

// export const groupAssignedListSelector = createSelector(groupsSelector, data => data[GROUP_ASSIGNED_LIST])

export const groupIdSelector = createSelector(
  (store: RootState) => store.router.location,
  selected => {
    return selected.query.group
  }
)

// export const groupExportSelector = createSelector(groupsSelector, prop(GROUP_EXPORT))

// export const groupAllOptionsSelector = createSelector(groupAllSelector, selected => {
//   return {
//     loading: selected.loading,
//     error: selected.error,
//     options: Array.isArray(selected.data?.groups)
//       ? selected.data?.groups.map(item => ({ label: propOr('', 'title', item.group), value: item.group?.id }))
//       : [],
//   }
// })
