const NODE_ENV = process.env.NODE_ENV || 'development'

export const API_SCHEME = NODE_ENV !== 'development' ? 'https' : 'http'
// export const API_SCHEME = NODE_ENV !== 'development' ? 'https' : 'http'
export const API_WS_SCHEME = NODE_ENV !== 'development' ? 'wss' : 'ws'

export const API_LOCAL = '192.168.0.12:9450' // '192.168.100.32:9120'
export const API_LIVE = 'exam.slash.uz'
export const API_HOST = NODE_ENV !== 'development' ? API_LIVE : API_LOCAL

export const API_PROJECT = '/edulab/exam' as const
export const API_V1 = '/api/v1' as const
export const API_URL = `${API_HOST}${API_PROJECT}${API_V1}` as const

export const UPLOAD_API = `${API_PROJECT}${API_V1}/media/upload/react` as const
