import { pathOr } from 'ramda/es'
import { all, fork, takeEvery } from 'redux-saga/effects'

import { WatchSaga, getPageSaga, watchSaga, watchSagaFulfilled, watchSagaRejected } from '../../../helpers/customSaga'
import * as actions from '../../../redux/actions'
import * as API from '../api'
import actionTypes, {
  TEACHING_GROUP,
  TEACHING_GROUP_ALL,
  TEACHING_GROUP_CREATE,
  TEACHING_GROUP_DELETE,
  TEACHING_GROUP_GRADES,
  TEACHING_GROUP_LIST,
  TEACHING_GROUP_STUDENTS,
  TEACHING_GROUP_TEACHERS,
  TEACHING_GROUP_TYPES,
} from '../constants/actionTypes'

function watchTeachingGroup() {
  return watchSaga({
    action: actionTypes[TEACHING_GROUP],
    api: API.teachingGroupGetById,
  })
}

function watchTeachingGroupRejected() {
  return watchSagaRejected({
    action: actionTypes[TEACHING_GROUP],
    message: true,
  })
}

function watchTeachingGroupList() {
  return watchSaga({
    action: actionTypes[TEACHING_GROUP_LIST],
    api: API.teachingGroupList,
  })
}

function watchTeachingGroupListRejected() {
  return watchSagaRejected({
    action: actionTypes[TEACHING_GROUP_LIST],
    message: true,
  })
}

function watchTeachingGroupAll() {
  return watchSaga({
    action: actionTypes[TEACHING_GROUP_ALL],
    api: API.teachingGroupAll,
  })
}

function watchTeachingGroupAllRejected() {
  return watchSagaRejected({
    action: actionTypes[TEACHING_GROUP_ALL],
    message: true,
  })
}

const teachingGroupCreateSaga = new WatchSaga({
  actionType: actionTypes[TEACHING_GROUP_CREATE],
  api: API.teachingGroupCreate,
})

function watchTeachingGroupDelete() {
  return watchSaga({
    action: actionTypes[TEACHING_GROUP_DELETE],
    api: API.teachingGroupDelete,
  })
}

function* watchTeachingGroupDeleteFulfilled() {
  yield takeEvery(actionTypes[TEACHING_GROUP_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
  })
}

function* watchTeachingGroupDeleteRejected() {
  yield takeEvery(actionTypes[TEACHING_GROUP_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchTeachingGroupGrades() {
  return watchSaga({
    action: actionTypes[TEACHING_GROUP_GRADES],
    api: API.teachingGroupGrades,
  })
}

function watchTeachingGroupGradesRejected() {
  return watchSagaRejected({
    action: actionTypes[TEACHING_GROUP_GRADES],
    message: true,
  })
}

function watchTeachingGroupStudents() {
  return watchSaga({
    action: actionTypes[TEACHING_GROUP_STUDENTS],
    api: API.teachingGroupStudents,
  })
}

function watchTeachingGroupStudentsFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[TEACHING_GROUP_STUDENTS],
    message: {
      description: 'Successfully added',
    },
  })
}

function watchTeachingGroupStudentsRejected() {
  return watchSagaRejected({
    action: actionTypes[TEACHING_GROUP_STUDENTS],
    message: true,
  })
}

function watchTeachingGroupTeachers() {
  return watchSaga({
    action: actionTypes[TEACHING_GROUP_TEACHERS],
    api: API.teachingGroupTeachers,
  })
}

function watchTeachingGroupTeachersFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[TEACHING_GROUP_TEACHERS],
    message: {
      description: 'Successfully added',
    },
  })
}

function watchTeachingGroupTeachersRejected() {
  return watchSagaRejected({
    action: actionTypes[TEACHING_GROUP_TEACHERS],
    message: true,
  })
}

function watchTeachingGroupTypes() {
  return watchSaga({
    action: actionTypes[TEACHING_GROUP_TYPES],
    api: API.teachingGroupTypes,
  })
}

function watchTeachingGroupTypesRejected() {
  return watchSagaRejected({
    action: actionTypes[TEACHING_GROUP_TYPES],
    message: true,
  })
}

export function* teachingGroupSaga() {
  yield all([
    fork(watchTeachingGroup),
    fork(watchTeachingGroupRejected),
    fork(watchTeachingGroupList),
    fork(watchTeachingGroupListRejected),
    fork(watchTeachingGroupAll),
    fork(watchTeachingGroupAllRejected),
    fork(teachingGroupCreateSaga.watch),
    fork(teachingGroupCreateSaga.watchFulfilled),
    fork(teachingGroupCreateSaga.watchRejected),
    fork(watchTeachingGroupDelete),
    fork(watchTeachingGroupDeleteFulfilled),
    fork(watchTeachingGroupDeleteRejected),
    fork(watchTeachingGroupGrades),
    fork(watchTeachingGroupGradesRejected),
    fork(watchTeachingGroupStudents),
    fork(watchTeachingGroupStudentsFulfilled),
    fork(watchTeachingGroupStudentsRejected),
    fork(watchTeachingGroupTeachers),
    fork(watchTeachingGroupTeachersFulfilled),
    fork(watchTeachingGroupTeachersRejected),
    fork(watchTeachingGroupTypes),
    fork(watchTeachingGroupTypesRejected),
  ])
}
