import { path, pathOr, prop } from 'ramda/es'
import { all, fork, put, takeEvery } from 'redux-saga/effects'
import actionTypes, {
  CCLS_MODULE,
  CCLS_MODULE_CREATE,
  CCLS_MODULE_DELETE,
  CCLS_MODULE_LIST,
  CCLS_MODULE_ALL,
  CCLS_MODULE_TEACHER_UPDATE,
  CCLS_MODULES_FILTER,
} from '../constants/actionTypes'
import * as API from '../api'
import * as actions from '../../../../redux/actions'
import { watchSaga } from '../../../../helpers/customSaga'
import { toast } from 'react-toastify'
import { ErrorToast } from '../../../../components/Toasts'

function watchCclsModule() {
  return watchSaga({
    action: actionTypes[CCLS_MODULE],
    api: API.cclsModule,
  })
}

function watchModulesFilter() {
  return watchSaga({
    action: actionTypes[CCLS_MODULES_FILTER],
    api: API.modulesFilter,
  })
}

function watchCclsModuleCreate() {
  return watchSaga({
    action: actionTypes[CCLS_MODULE_CREATE],
    api: API.cclsModuleCreate,
  })
}

function* watchCclsModuleCreateFulfilled() {
  yield takeEvery(actionTypes[CCLS_MODULE_CREATE].fulfilled, function* (payload) {
    const handleCloseModal = path(['args', 'handleCloseModal'], payload) || (() => {})
    handleCloseModal()
    yield put(actions.cclsModuleClear())
    yield put(actions.cclsModuleList())
  })
}

function* watchCclsModuleCreateRejected() {
  yield takeEvery(actionTypes[CCLS_MODULE_CREATE].rejected, function ({ payload }) {
    toast.error(<ErrorToast text={prop('message', payload)} />)
    // toastr.error(prop('error', payload), prop('message', payload))
  })
}

function watchCclsModuleTeacherUpdate() {
  return watchSaga({
    action: actionTypes[CCLS_MODULE_TEACHER_UPDATE],
    api: API.cclsModuleTeacherUpdate,
  })
}

function* watchCclsModuleTeacherUpdateFulfilled() {
  yield takeEvery(actionTypes[CCLS_MODULE_TEACHER_UPDATE].fulfilled, function* () {
    yield put(actions.cclsModuleList())
  })
}

function* watchCclsModuleTeacherUpdateRejected() {
  yield takeEvery(actionTypes[CCLS_MODULE_TEACHER_UPDATE].rejected, function ({ payload }) {
    toast.error(<ErrorToast text={prop('message', payload)} />)
    // toastr.error(prop('error', payload), prop('message', payload))
  })
}

function watchCclsModuleDelete() {
  return watchSaga({
    action: actionTypes[CCLS_MODULE_DELETE],
    api: API.cclsModuleDelete,
  })
}

function* watchCclsModuleDeleteFulfilled() {
  yield takeEvery(actionTypes[CCLS_MODULE_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield put(actions.cclsModuleList())
  })
}

function* watchCclsModuleDeleteRejected() {
  yield takeEvery(actionTypes[CCLS_MODULE_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
    yield put(actions.cclsModuleList())
  })
}

function watchCclsModuleList() {
  return watchSaga({
    action: actionTypes[CCLS_MODULE_LIST],
    api: API.cclsModuleList,
  })
}

function watchCclsModuleAll() {
  return watchSaga({
    action: actionTypes[CCLS_MODULE_ALL],
    api: API.cclsModuleAll,
  })
}

export function* cclsModuleSaga() {
  yield all([
    fork(watchCclsModule),
    fork(watchCclsModuleCreate),
    fork(watchCclsModuleCreateFulfilled),
    fork(watchCclsModuleCreateRejected),
    fork(watchCclsModuleTeacherUpdate),
    fork(watchCclsModuleTeacherUpdateFulfilled),
    fork(watchCclsModuleTeacherUpdateRejected),
    fork(watchCclsModuleDelete),
    fork(watchCclsModuleDeleteFulfilled),
    fork(watchCclsModuleDeleteRejected),
    fork(watchCclsModuleList),
    fork(watchCclsModuleAll),
    fork(watchModulesFilter),
  ])
}
