import { all } from 'redux-saga/effects'

import { academicYearSaga } from '../module/AcademicYears/redux/saga'
import { accountSaga } from '../module/Account/redux/saga'
import { assessmentSagas } from '../module/Assessment/redux/saga'
import { courseCreateSaga } from '../module/Course/create/redux/saga'
import { examSaga } from '../module/Exam/redux/saga'
import { excelReportSagas } from '../module/ExcelReport/redux/saga'
import { lessonSaga } from '../module/Lesson/redux/saga'
import { cclsModuleSaga } from '../module/Modules/activate/redux/saga'
import { moduleSaga } from '../module/Modules/create/redux/saga'
import { questionSaga } from '../module/Question/redux/saga'
import { questionsBankSaga } from '../module/QuestionsBank/redux/saga'
import rolesSaga from '../module/Roles/redux/saga'
import { studentSaga } from '../module/Student/redux/saga'
import { teacherSaga } from '../module/Teacher/redux/saga'
import { teachingGroupSaga } from '../module/TeachingGroup/redux/saga'

export default function* rootSaga() {
  yield all([
    accountSaga(),
    rolesSaga(),
    lessonSaga(),
    cclsModuleSaga(),
    moduleSaga(),
    courseCreateSaga(),
    studentSaga(),
    teacherSaga(),
    academicYearSaga(),
    examSaga(),
    teachingGroupSaga(),
    assessmentSagas(),
    questionsBankSaga(),
    questionSaga(),
    excelReportSagas(),
  ])
}
