import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const facultyAll: ApiFunc<`${typeof API.FACULTY_ALL}`, 'get'> = data =>
  instance.get(API.FACULTY_ALL, { params: data })

export const lmsFacultyAll: ApiFunc<`${typeof API.LMS_FACULTY_ALL}`, 'get'> = data =>
  instance.get(API.LMS_FACULTY_ALL, { params: data })
