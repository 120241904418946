import { path } from 'ramda/es'
import { createSelector } from 'reselect'
import { GLOBAL_STATE, LESSON_LIST, LESSON_ALL, LESSON, LESSON_CREATE } from '../constants/state'

export const lessonSelector = createSelector(path([GLOBAL_STATE, LESSON]), data => data)

export const lessonListSelector = createSelector(path([GLOBAL_STATE, LESSON_LIST]), data => data)

export const lessonAllSelector = createSelector(path([GLOBAL_STATE, LESSON_ALL]), data => data)

export const lessonCreateSelector = createSelector(path([GLOBAL_STATE, LESSON_CREATE]), data => data)

export const lessonSelectors = {
  lessonSelector,
  lessonListSelector,
  lessonAllSelector,
  lessonCreateSelector,
}
