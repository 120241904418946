import { WatchSaga, watchSaga, watchSagaFulfilled } from '@helpers/customSaga'
import { pathOr } from 'ramda'
import { all, fork, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, { LESSON, LESSON_ALL, LESSON_CREATE, LESSON_DELETE, LESSON_LIST } from '../constants/actionTypes'

function watchLesson() {
  return watchSaga({
    action: actionTypes[LESSON],
    api: API.lesson,
  })
}

function watchLessonCreate() {
  return watchSaga({
    action: actionTypes[LESSON_CREATE],
    api: API.lessonCreate,
  })
}

function watchLessonCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[LESSON_CREATE],
    message: {
      title: 'Success',
      description: 'Lesson has been created',
    },
    shouldDoFulfilledFunction: true,
  })
}

function watchLessonDelete() {
  return watchSaga({
    action: actionTypes[LESSON_DELETE],
    api: API.lessonDelete,
  })
}

function* watchLessonDeleteFulfilled() {
  yield takeEvery(actionTypes[LESSON_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    // yield put(actions.lessonList())
  })
}

function* watchLessonDeleteRejected() {
  yield takeEvery(actionTypes[LESSON_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchLessonList() {
  return watchSaga({
    action: actionTypes[LESSON_LIST],
    api: API.lessonList,
  })
}

const lessonAllSaga = new WatchSaga({ actionType: actionTypes[LESSON_ALL], api: API.lessonAll })

export function* lessonSaga() {
  yield all([
    fork(watchLesson),
    fork(watchLessonCreate),
    fork(watchLessonCreateFulfilled),
    fork(watchLessonDelete),
    fork(watchLessonDeleteFulfilled),
    fork(watchLessonDeleteRejected),
    fork(watchLessonList),
    fork(lessonAllSaga.watch),
    fork(lessonAllSaga.watchFulfilled),
    fork(lessonAllSaga.watchRejected),
  ])
}
