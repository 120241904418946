import { path } from 'ramda/es'
import { createSelector } from 'reselect'
import { GLOBAL_STATE, COURSE, COURSE_ALL, COURSE_CREATE, COURSE_LIST } from '../constants/state'

export const courseSelector = createSelector(path([GLOBAL_STATE, COURSE]), data => data)

export const courseAllSelector = createSelector(path([GLOBAL_STATE, COURSE_ALL]), data => data)

export const courseListSelector = createSelector(path([GLOBAL_STATE, COURSE_LIST]), data => data)

export const courseCreateSelector = createSelector(path([GLOBAL_STATE, COURSE_CREATE]), data => data)

export const courseCreateSelectors = {
  courseSelector,
  courseAllSelector,
  courseListSelector,
  courseCreateSelector,
}
