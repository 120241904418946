import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  GLOBAL_STATE,
  GROUP_ALL,
  GROUP_ASSIGN,
  GROUP_ASSIGNED_LIST,
  GROUP_DELETE,
  GROUP_EDIT,
  GROUP_EXPORT,
  GROUP_LIST,
  GROUP_SHUFFLE,
  GROUP_STUDENT_TRANSFER,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const GROUPING = GLOBAL_STATE

const groupSlice = createSlice({
  name: GROUPING,
  initialState,
  reducers: {},
  extraReducers(builder) {
    // builder
    //   .addCase(actionCreators.groupExport.pending, state => {
    //     state[GROUP_EXPORT].loading = true
    //   })
    //   .addCase(actionCreators.groupExport.rejected, (state, action) => {
    //     state[GROUP_EXPORT].loading = false
    //     state[GROUP_EXPORT].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.groupExport.fulfilled, (state, action) => {
    //     state[GROUP_EXPORT].loading = false
    //     state[GROUP_EXPORT].error = null
    //     state[GROUP_EXPORT].data = action.payload
    //   })

    builder
      .addCase(actionCreators.groupAll.pending, state => {
        state[GROUP_ALL].loading = true
      })
      .addCase(actionCreators.groupAll.rejected, (state, action) => {
        state[GROUP_ALL].loading = false
        state[GROUP_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.groupAll.fulfilled, (state, action) => {
        state[GROUP_ALL].loading = false
        state[GROUP_ALL].error = null
        state[GROUP_ALL].data = action.payload
      })

    // builder
    //   .addCase(actionCreators.groupList.pending, state => {
    //     state[GROUP_LIST].loading = true
    //   })
    //   .addCase(actionCreators.groupList.rejected, (state, action) => {
    //     state[GROUP_LIST].loading = false
    //     state[GROUP_LIST].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.groupList.fulfilled, (state, action) => {
    //     state[GROUP_LIST].loading = false
    //     state[GROUP_LIST].error = null
    //     state[GROUP_LIST].data = action.payload
    //   })

    // builder
    //   .addCase(actionCreators.groupAssign.pending, state => {
    //     state[GROUP_ASSIGN].loading = true
    //   })
    //   .addCase(actionCreators.groupAssign.rejected, (state, action) => {
    //     state[GROUP_ASSIGN].loading = false
    //     state[GROUP_ASSIGN].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.groupAssign.fulfilled, (state, action) => {
    //     state[GROUP_ASSIGN].loading = false
    //     state[GROUP_ASSIGN].error = null
    //     state[GROUP_ASSIGN].data = action.payload
    //   })

    // builder
    //   .addCase(actionCreators.groupAssignedList.pending, state => {
    //     state[GROUP_ASSIGNED_LIST].loading = true
    //   })
    //   .addCase(actionCreators.groupAssignedList.rejected, (state, action) => {
    //     state[GROUP_ASSIGNED_LIST].loading = false
    //     state[GROUP_ASSIGNED_LIST].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.groupAssignedList.fulfilled, (state, action) => {
    //     state[GROUP_ASSIGNED_LIST].loading = false
    //     state[GROUP_ASSIGNED_LIST].error = null
    //     state[GROUP_ASSIGNED_LIST].data = action.payload
    //   })

    // builder
    //   .addCase(actionCreators.groupEdit.pending, state => {
    //     state[GROUP_EDIT].loading = true
    //   })
    //   .addCase(actionCreators.groupEdit.rejected, (state, action) => {
    //     state[GROUP_EDIT].loading = false
    //     state[GROUP_EDIT].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.groupEdit.fulfilled, (state, action) => {
    //     state[GROUP_EDIT].loading = false
    //     state[GROUP_EDIT].error = null
    //     state[GROUP_EDIT].data = action.payload
    //   })

    // builder
    //   .addCase(actionCreators.groupDelete.pending, state => {
    //     state[GROUP_DELETE].loading = true
    //   })
    //   .addCase(actionCreators.groupDelete.rejected, (state, action) => {
    //     state[GROUP_DELETE].loading = false
    //     state[GROUP_DELETE].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.groupDelete.fulfilled, (state, action) => {
    //     state[GROUP_DELETE].loading = false
    //     state[GROUP_DELETE].error = null
    //     state[GROUP_DELETE].data = action.payload
    //   })

    // builder
    //   .addCase(actionCreators.groupShuffle.pending, state => {
    //     state[GROUP_SHUFFLE].loading = true
    //   })
    //   .addCase(actionCreators.groupShuffle.rejected, (state, action) => {
    //     state[GROUP_SHUFFLE].loading = false
    //     state[GROUP_SHUFFLE].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.groupShuffle.fulfilled, (state, action) => {
    //     state[GROUP_SHUFFLE].loading = false
    //     state[GROUP_SHUFFLE].error = null
    //     state[GROUP_SHUFFLE].data = action.payload
    //   })

    // builder
    //   .addCase(actionCreators.groupStudentTransfer.pending, state => {
    //     state[GROUP_STUDENT_TRANSFER].loading = true
    //   })
    //   .addCase(actionCreators.groupStudentTransfer.rejected, (state, action) => {
    //     state[GROUP_STUDENT_TRANSFER].loading = false
    //     state[GROUP_STUDENT_TRANSFER].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.groupStudentTransfer.fulfilled, (state, action) => {
    //     state[GROUP_STUDENT_TRANSFER].loading = false
    //     state[GROUP_STUDENT_TRANSFER].error = null
    //     state[GROUP_STUDENT_TRANSFER].data = action.payload
    //   })

    // builder.addCase(actionCreators.groupListClear, state => {
    //   state[GROUP_LIST] = defaultState()
    // })

    builder.addCase(actionCreators.groupAllClear, state => {
      state[GROUP_ALL] = defaultState()
    })

    // builder.addCase(actionCreators.groupAssignClear, state => {
    //   state[GROUP_ASSIGN] = defaultState()
    // })

    // builder.addCase(actionCreators.groupAssignedListClear, state => {
    //   state[GROUP_ASSIGNED_LIST] = defaultState()
    // })

    // builder.addCase(actionCreators.groupShuffleClear, state => {
    //   state[GROUP_SHUFFLE] = defaultState()
    // })

    // builder.addCase(actionCreators.groupStudentTransferClear, state => {
    //   state[GROUP_STUDENT_TRANSFER] = defaultState()
    // })
  },
})

export const { reducer: groupReducer } = groupSlice
