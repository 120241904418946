// ** React Imports
// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesFr from '@assets/data/locales/fr.json'
import messagesPt from '@assets/data/locales/pt.json'
// ** User Language Data
import userMessagesRu from '@assets/data/locales/ru.json'
import userMessagesUz from '@assets/data/locales/uz.json'
import { flattenMessages } from '@helpers/flattenMessages'
import { getLanguage, setLanguage } from '@helpers/storageLanguage'
import messagesDe from '@src/assets/data/locales/de.json'
import { authUserInfo } from '@store/selector'
import { path } from 'ramda'
import { createContext, useEffect, useState } from 'react'
// ** Intl Provider Import
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn },
  de: { ...messagesDe },
  fr: { ...messagesFr },
  pt: { ...messagesPt },
  ru: { ...userMessagesRu },
  uz: { ...userMessagesUz },
}

const institutionSelector = state => {
  const data = authUserInfo(state)
  return path(['institution_info', 'id'], data)
}

// ** Create Context
const Context = createContext(getLanguage() || 'uz')

const IntlProviderWrapper = ({ children, defaultLocale }) => {
  const defaultLanguage = getLanguage() || defaultLocale
  const institutionId = useSelector(institutionSelector)

  // ** States
  const [locale, setLocale] = useState(defaultLanguage)
  const [messages, setMessages] = useState(menuMessages[defaultLanguage])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
    setLanguage(lang)
  }

  useEffect(() => {
    if (institutionId === 2) {
      switchLanguage('en')
    }
  }, [institutionId])

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider
        key={locale}
        locale={locale}
        onError={() => {}}
        messages={flattenMessages(messages)}
        defaultLocale={defaultLanguage}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
