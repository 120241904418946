import { createAction } from '../../../helpers/createAction'

export const ACADEMIC_YEAR = 'ACADEMIC_YEAR'
export const ACADEMIC_YEAR_CREATE = 'ACADEMIC_YEAR_CREATE'
export const ACADEMIC_YEAR_DELETE = 'ACADEMIC_YEAR_DELETE'
export const ACADEMIC_YEAR_LIST = 'ACADEMIC_YEAR_LIST'
export const ACADEMIC_YEAR_ALL = 'ACADEMIC_YEAR_ALL'

export default {
  [ACADEMIC_YEAR]: createAction(ACADEMIC_YEAR),
  [ACADEMIC_YEAR_CREATE]: createAction(ACADEMIC_YEAR_CREATE),
  [ACADEMIC_YEAR_DELETE]: createAction(ACADEMIC_YEAR_DELETE),
  [ACADEMIC_YEAR_LIST]: createAction(ACADEMIC_YEAR_LIST),
  [ACADEMIC_YEAR_ALL]: createAction(ACADEMIC_YEAR_ALL),
}
