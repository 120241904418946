import { path } from 'ramda/es'
import { createSelector } from 'reselect'
import {
  QUESTION_DELETE,
  QUESTION_ALL,
  QUESTION,
  QUESTION_LIST,
  GLOBAL_STATE,
  QUESTION_CREATE,
  QUESTION_TYPES,
} from '../constants/state'

export const questionSelector = createSelector(path([GLOBAL_STATE, QUESTION]), data => data)

export const questionListSelector = createSelector(path([GLOBAL_STATE, QUESTION_LIST]), data => data)

export const questionAllSelector = createSelector(path([GLOBAL_STATE, QUESTION_ALL]), data => data)

export const questionCreateSelector = createSelector(path([GLOBAL_STATE, QUESTION_CREATE]), data => data)

export const questionDeleteSelector = createSelector(path([GLOBAL_STATE, QUESTION_DELETE]), data => data)

export const questionTypesSelector = createSelector(path([GLOBAL_STATE, QUESTION_TYPES]), data => data)

export const questionSelectors = {
  questionSelector,
  questionListSelector,
  questionAllSelector,
  questionCreateSelector,
  questionDeleteSelector,
  questionTypesSelector,
}