import { STORAGE_INSTITUTION } from '../constants/storage'
import { isNil } from 'ramda'

export const getInstitution = () => {
  try {
    const institution = localStorage.getItem(STORAGE_INSTITUTION)
    if (!isNil(institution)) {
      return JSON.parse(institution)
    }
  } catch (e) {
    console.error('Error with institution info: ', e)
  }
  return null
}

// export const getSelectedInstitution = () => {
//   try {
//     const selectedInstitution = localStorage.getItem(STORAGE_SELECTED_INSTITUTION)
//     if (selectedInstitution) {
//       return JSON.parse(selectedInstitution)
//     }
//   } catch (e) {
//     console.error('Error in getting selected institution: ', e)
//   }
//   return null
// }

export const setInstitutionId = id => {
  localStorage.setItem(STORAGE_INSTITUTION, id)
}

// export const setSelectedInstitution = institution => {
//   localStorage.setItem(STORAGE_SELECTED_INSTITUTION, institution)
// }

export const removeInstitution = () => {
  localStorage.removeItem(STORAGE_INSTITUTION)
}

// export const removeSelectedInstitution = () => {
//   localStorage.removeItem(STORAGE_SELECTED_INSTITUTION)
// }
