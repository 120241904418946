export function toCamelCase(key = '') {
  return key
    .split('_')
    .map((name, idx) => {
      if (idx === 0) return name.toLocaleLowerCase()
      const firstLetter = name[0]
      const restLetters = name.slice(1).toLocaleLowerCase()
      return `${firstLetter}${restLetters}`
    })
    .join('')
}
