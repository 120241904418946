import { STORAGE_LANGUAGE } from '../constants/storage'

const DEFAULT_LANGUAGE = 'uz'
const DEFAULT_LANGUAGE_OPTION = { label: 'Uz' as const, value: 'uz' as const }

export const getLanguage = () => {
  const availableLangs: Record<string, 'en' | 'uz' | 'ru'> = {
    en: 'en',
    uz: 'uz',
    ru: 'ru',
  }
  const storageLanguage = localStorage.getItem(STORAGE_LANGUAGE)

  return storageLanguage ? availableLangs[storageLanguage] : DEFAULT_LANGUAGE
}

export const setLanguage = (lang: 'en' | 'ru' | 'uz') => {
  localStorage.setItem(STORAGE_LANGUAGE, lang)
}

export const removeLanguage = () => {
  localStorage.removeItem(STORAGE_LANGUAGE)
}
