import { BACHELOR_STUDENT, STUDENT, TEST_STUDENT } from '@constants/userRoles'
import { getToken, removeToken, removeUserInfo, setToken } from '@helpers/storageToken'
import { getUserInfo, setUserInfo } from '@helpers/storageUserInfo'
import { AnyAction, Dispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { definitions } from '@schema'
import { EMAIL_VERIFICATION, STUDENT_DASHBOARD, TEACHER_HOME, UPDATE_PASSWORD } from '@src/constants/routes'
import { AxiosError } from '@src/helpers/httpClient'
import { removeInstitution } from '@src/helpers/storageInstitution'
import { getLanguage, removeLanguage, setLanguage } from '@src/helpers/storageLanguage'
import { FulfilledAction, PayloadCreatorParams, PendingAction, RejectedAction } from '@store/types'
import axios from 'axios'
import { push } from 'connected-react-router'
import { omit, pathOr, prop } from 'ramda'
import { toast } from 'react-toastify'

import {
  sendSms as sendSmsApi,
  signIn,
  signUp,
  userInfo as userInfoApi,
  userUpdatePassword,
  verifyEmail as verifyEmailApi,
} from '../api'
import { AUTH_LOGIN, SIGN_UP, SMS_SEND, USER_INFO, USER_UPDATE_PASSWORD, VERIFY_EMAIL } from '../constants/actionTypes'

export const setUserInfoToStorage = (payload: { token: string | null; user_info: definitions['UserInfo'] }) => {
  const token = prop('token', payload)
  const userInfo = prop('user_info', payload)
  const institutionId = pathOr<1 | 2>(1, ['selected_institution', 'value'], userInfo)
  setToken(token)
  setUserInfo(userInfo)
  setLanguage(institutionId === 2 ? 'en' : getLanguage())
  return { token, userInfo }
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  if (action.type.endsWith('/rejected')) {
    const type = action.type.replace('/rejected', '')
    return (
      type !== `${AUTH}/${USER_INFO}` && type !== `${AUTH}/${USER_UPDATE_PASSWORD}` && type !== `${AUTH}/${AUTH_LOGIN}`
    )
  }
  return false
}

function isSettledAction(action: AnyAction): action is FulfilledAction | RejectedAction {
  if (action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected')) {
    const type = action.type.replace('/fulfilled', '').replace('/rejected', '')
    return (
      type !== `${AUTH}/${USER_INFO}` && type !== `${AUTH}/${USER_UPDATE_PASSWORD}` && type !== `${AUTH}/${AUTH_LOGIN}`
    )
  }
  return false
}

const isPendingAction = (action: AnyAction): action is PendingAction => {
  if (action.type.endsWith('/pending')) {
    const type = action.type.replace('/pending', '')
    return (
      type !== `${AUTH}/${USER_INFO}` && type !== `${AUTH}/${USER_UPDATE_PASSWORD}` && type !== `${AUTH}/${AUTH_LOGIN}`
    )
  }
  return false
}

export const AUTH = 'Auth'

function redirectUser(userInfo: definitions['UserInfo'], dispatch: Dispatch) {
  if (userInfo.required_actions?.find(item => item === 'VERIFY_EMAIL')) {
    dispatch(push(EMAIL_VERIFICATION))
  } else if (userInfo.required_actions?.find(item => item === 'UPDATE_PASSWORD')) {
    dispatch(push(UPDATE_PASSWORD))
    // eslint-disable-next-line brace-style
  }
  // else if (userInfo.required_actions?.find(item => item === 'ENROLLMENT_REQUIRED')) {
  //   dispatch(push(ENROLLMENT_PAGE))
  // }
  else if (
    Object.prototype.hasOwnProperty.call(userInfo.roles_map, BACHELOR_STUDENT) ||
    Object.prototype.hasOwnProperty.call(userInfo.roles_map, TEST_STUDENT) ||
    Object.prototype.hasOwnProperty.call(userInfo.roles_map, STUDENT)
    // Object.prototype.hasOwnProperty.call(userInfo.roles_map, MASTER_STUDENT)
  ) {
    dispatch(push(STUDENT_DASHBOARD))
  } else {
    dispatch(push(TEACHER_HOME))
  }
}

export const authLogin = createAsyncThunk(
  AUTH_LOGIN,
  async (params: PayloadCreatorParams<'/signin', 'post'>, { dispatch, rejectWithValue }) => {
    try {
      const response = await signIn(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      const { userInfo } = setUserInfoToStorage({ token: response.data.token, user_info: response.data.user_info })
      response.data.user_info = userInfo
      params?.onFulfilled && params.onFulfilled(response.data)
      redirectUser(response.data.user_info, dispatch)
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = e.response?.data
        toast.error((e.response?.data.message || e.message) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }

      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const authPasswordReset = createAsyncThunk(
  USER_UPDATE_PASSWORD,
  async (params: PayloadCreatorParams<'/user/own/password', 'put'>, { dispatch, rejectWithValue }) => {
    try {
      const response = await userUpdatePassword(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      const { userInfo } = setUserInfoToStorage({ token: response.data.token, user_info: response.data.user_info })
      response.data.user_info = userInfo
      params?.onFulfilled && params.onFulfilled(response.data)
      redirectUser(response.data.user_info, dispatch)
      // dispatch(layoutMenuHidden(false))
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = e.response?.data
        toast.error((e.response?.data.message || e.message) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const authUserInfo = createAsyncThunk(
  USER_INFO,
  async (params: PayloadCreatorParams<'/user/me/info', 'get'> | undefined, { rejectWithValue, dispatch }) => {
    try {
      const response = await userInfoApi(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      const { userInfo } = setUserInfoToStorage({ token: getToken(), user_info: response.data })
      response.data = userInfo
      params?.onFulfilled && params.onFulfilled(response.data)
      // dispatch(layoutMenuHidden(false))
      if (userInfo.required_actions?.find(item => item === 'VERIFY_EMAIL')) {
        dispatch(push(EMAIL_VERIFICATION))
      } else if (userInfo.required_actions?.find(item => item === 'UPDATE_PASSWORD')) {
        dispatch(push(UPDATE_PASSWORD))
      }
      return response.data
    } catch (e) {
      let error: AxiosError | Record<string, never> = {}
      if (axios.isAxiosError(e)) {
        error = e.response?.data
        toast.error((e.response?.data.message || e.message) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      dispatch(() => {
        removeToken()
        removeUserInfo()
        removeInstitution()
        // removeSelectedInstitution()
        removeLanguage()
        dispatch(authLogout())
      })
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const verifyEmail = createAsyncThunk(
  VERIFY_EMAIL,
  async (params: PayloadCreatorParams<'/verify-email', 'post'>, { dispatch, rejectWithValue }) => {
    try {
      const filteredParams = omit(['onFulfilled', 'onRejected', 'onSettled'], params)
      const response = await verifyEmailApi(filteredParams)
      const { userInfo } = setUserInfoToStorage({ token: response.data.token, user_info: response.data.user_info })
      response.data.user_info = userInfo
      params?.onFulfilled && params.onFulfilled(response.data)
      redirectUser(response.data.user_info, dispatch)
      // dispatch(layoutMenuHidden(false))
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = e.response?.data
        toast.error((e.response?.data.message || e.message) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const authSignUp = createAsyncThunk(
  SIGN_UP,
  async (params: PayloadCreatorParams<'/signup', 'post'>, { dispatch, rejectWithValue }) => {
    try {
      const response = await signUp(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      const { userInfo } = setUserInfoToStorage({ token: response.data.token, user_info: response.data.user_info })
      response.data.user_info = userInfo
      params?.onFulfilled && params.onFulfilled(response.data)
      redirectUser(response.data.user_info, dispatch)
      // dispatch(layoutMenuHidden(false))
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = e.response?.data
        toast.error((e.response?.data.message || e.message) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)

export const sendSms = createAsyncThunk(
  SMS_SEND,
  async (params: PayloadCreatorParams<'/sms/send', 'post'>, { rejectWithValue }) => {
    try {
      const response = await sendSmsApi(omit(['onFulfilled', 'onRejected', 'onSettled'], params))
      params?.onFulfilled && params.onFulfilled(response.data)
      return response.data
    } catch (e) {
      let error = {}
      if (axios.isAxiosError(e)) {
        error = e.response?.data
        toast.error((e.response?.data.message || e.message) as string, { style: { wordBreak: 'break-word' } })
      } else {
        console.error(e)
      }
      params?.onRejected && params.onRejected({ error })
      return rejectWithValue(error)
    } finally {
      params?.onSettled && params.onSettled()
    }
  }
)
const initialState = {
  token: getToken(),
  userInfo: getUserInfo(),
  login: null,
  error: null,
  [VERIFY_EMAIL]: {
    loading: false,
    error: null,
    data: null,
  },
  [SIGN_UP]: {
    loading: false,
    error: null,
    data: null,
  },
  [SMS_SEND]: {
    loading: false,
    error: null,
    data: null,
  },
  loading: false,
}

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    authLogout(state) {
      state.token = null
      state.userInfo = null
    },
    authUserInfoClear(state) {
      state.token = null
      state.userInfo = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authLogin.pending, state => {
        state.loading = true
      })
      .addCase(authLogin.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.token = action.payload.token
        state.userInfo = action.payload.user_info
      })
      .addCase(authLogin.rejected, (state, action) => {
        ;(state.error as unknown as AxiosError) = action.payload as AxiosError
        state.loading = false
      })

    builder
      .addCase(authPasswordReset.pending, state => {
        state.loading = true
      })
      .addCase(authPasswordReset.rejected, (state, action) => {
        ;(state.error as unknown as AxiosError) = action.payload as AxiosError
        state.loading = false
      })
      .addCase(authPasswordReset.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.token = action.payload.token
        state.userInfo = action.payload.user_info
      })

    builder
      .addCase(authUserInfo.pending, state => {
        state.loading = true
      })
      .addCase(authUserInfo.rejected, (state, action) => {
        ;(state.error as unknown) = action.payload
        state.loading = false
      })
      .addCase(authUserInfo.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.userInfo = action.payload
      })

    builder.addCase(verifyEmail.pending, state => {
      state[VERIFY_EMAIL].loading = true
    })
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state[VERIFY_EMAIL].loading = false
      ;(state.error as unknown) = action.payload
    })
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state[VERIFY_EMAIL].error = null
      state.token = action.payload.token
      state.userInfo = action.payload.user_info
    })

    builder
      .addCase(authSignUp.pending, state => {
        state[SIGN_UP].loading = true
      })
      .addCase(authSignUp.rejected, (state, action) => {
        state[SIGN_UP].loading = false
        ;(state.error as unknown) = action.payload
      })
      .addCase(authSignUp.fulfilled, (state, action) => {
        state[SIGN_UP].loading = false
        state.token = action.payload.token
        state.userInfo = action.payload.user_info
        state[SIGN_UP].error = null
      })

    builder
      .addCase(sendSms.pending, state => {
        state[SMS_SEND].loading = true
      })
      .addCase(sendSms.rejected, (state, action) => {
        state[SMS_SEND].loading = false
        ;(state.error as unknown) = action.payload
      })
      .addCase(sendSms.fulfilled, state => {
        state[SMS_SEND].loading = false
        state[SMS_SEND].error = null
      })

    // builder.addMatcher(isPendingAction, (state, action) => {
    //   const type = action.type.replace('/pending', '') as `${typeof AUTH}/${
    //     | ActionTypesModule['VERIFY_EMAIL']
    //     | ActionTypesModule['SIGN_UP']
    //     | ActionTypesModule['SMS_SEND']}`
    //   state[type].loading = true
    // })
    // builder.addMatcher(isRejectedAction, (state, action) => {
    //   const type = action.type.replace('/rejected', '') as `${typeof AUTH}/${
    //     | ActionTypesModule['VERIFY_EMAIL']
    //     | ActionTypesModule['SIGN_UP']
    //     | ActionTypesModule['SMS_SEND']}`
    //   ;(state[type]['error'] as unknown) = action.payload
    // })
    // builder.addMatcher(isSettledAction, (state, action) => {
    //   const type = action.type.replace('/rejected', '').replace(' / fulfilled', '') as `${typeof AUTH}/${
    //     | ActionTypesModule['VERIFY_EMAIL']
    //     | ActionTypesModule['SIGN_UP']
    //     | ActionTypesModule['SMS_SEND']}`
    //   state[type].loading = false
    // })
  },
})

export const {
  reducer: authReducer,
  actions: { authLogout, authUserInfoClear },
} = authSlice
