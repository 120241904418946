import actionTypes, {
  ACADEMIC_YEAR,
  ACADEMIC_YEAR_CREATE,
  ACADEMIC_YEAR_DELETE,
  ACADEMIC_YEAR_LIST,
  ACADEMIC_YEAR_ALL,
} from '../constants/actionTypes'

export const academicYear = payload => ({
  type: actionTypes[ACADEMIC_YEAR].pending,
  payload,
})

export const academicYearCreate = payload => ({
  type: actionTypes[ACADEMIC_YEAR_CREATE].pending,
  payload,
})

export const academicYearDelete = payload => ({
  type: actionTypes[ACADEMIC_YEAR_DELETE].pending,
  payload,
})

export const academicYearList = payload => ({
  type: actionTypes[ACADEMIC_YEAR_LIST].pending,
  payload,
})

export const academicYearListClear = () => ({
  type: actionTypes[ACADEMIC_YEAR_LIST].clear,
})

export const academicYearAll = payload => ({
  type: actionTypes[ACADEMIC_YEAR_ALL].pending,
  payload,
})

export const academicYearAllClear = () => ({
  type: actionTypes[ACADEMIC_YEAR_ALL].clear,
})
