import { path } from 'ramda/es'
import { createSelector } from 'reselect'
import {
  GLOBAL_STATE,
  ACADEMIC_YEAR,
  ACADEMIC_YEAR_CREATE,
  ACADEMIC_YEAR_LIST,
  ACADEMIC_YEAR_ALL,
} from '../constants/state'

export const academicYearSelector = createSelector(path([GLOBAL_STATE, ACADEMIC_YEAR]), data => data)

export const academicYearAllSelector = createSelector(path([GLOBAL_STATE, ACADEMIC_YEAR_ALL]), data => data)

export const academicYearListSelector = createSelector(path([GLOBAL_STATE, ACADEMIC_YEAR_LIST]), data => data)

export const academicYearCreateSelector = createSelector(path([GLOBAL_STATE, ACADEMIC_YEAR_CREATE]), data => data)

export const academicYearsSelectors = {
  academicYearSelector,
  academicYearAllSelector,
  academicYearListSelector,
  academicYearCreateSelector,
}
