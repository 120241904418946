import { dissoc, pathOr } from 'ramda/es'
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import actionTypes, {
  ACCOUNT,
  ACCOUNT_CREATE,
  ACCOUNT_DELETE,
  ACCOUNT_LIST,
  ACCOUNT_ALL,
  ACCOUNT_PASSWORD_RESET,
  ACCOUNT_REQUIRED_ACTIONS,
  ACCOUNT_PROFILE_PIC,
  ACCOUNT_PROFILE_UPDATE,
  ACCOUNT_PASSWORD_UPDATE,
} from '../constants/actionTypes'
import * as API from '../api'
import * as actions from '../../../redux/actions'
import { getPageSaga, watchSaga, watchSagaFulfilled, watchSagaRejected } from '../../../helpers/customSaga'
import * as stateSelectors from '../../../redux/selector'
import { getPage } from '../../../helpers/get'
import { SuccessToast } from '../../../components/Toasts'

function watchAccount() {
  return watchSaga({
    action: actionTypes[ACCOUNT],
    api: API.account,
  })
}

function watchAccountCreate() {
  return watchSaga({
    action: actionTypes[ACCOUNT_CREATE],
    api: API.accountCreate,
  })
}

function* watchAccountCreateFulfilled() {
  yield takeEvery(actionTypes[ACCOUNT_CREATE].fulfilled, function* () {
    const { query } = yield select(stateSelectors.routerSelector)
    const get = getPage(query)
    yield put(actions.accountList({ ...get }))
  })
}

function watchAccountCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[ACCOUNT_CREATE],
    message: true,
  })
}

function watchAccountDelete() {
  return watchSaga({
    action: actionTypes[ACCOUNT_DELETE],
    api: API.accountDelete,
  })
}

function* watchAccountDeleteFulfilled() {
  yield takeEvery(actionTypes[ACCOUNT_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield getPageSaga(actions.accountList)
  })
}

function* watchAccountDeleteRejected() {
  yield takeEvery(actionTypes[ACCOUNT_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchAccountList() {
  return watchSaga({
    action: actionTypes[ACCOUNT_LIST],
    api: API.accountList,
  })
}

function watchAccountAll() {
  return watchSaga({
    action: actionTypes[ACCOUNT_ALL],
    api: API.accountAll,
  })
}

function watchAccountPasswordReset() {
  return watchSaga({
    action: actionTypes[ACCOUNT_PASSWORD_RESET],
    api: API.accountPasswordReset,
  })
}

// function* watchAccountPasswordResetFulfilled() {
//   yield takeEvery(actionTypes[ACCOUNT_PASSWORD_RESET].fulfilled, function* (payload) {
//     const token = path(['args', 'auth', 'token'], payload)
//     const authInfo = path(['args', 'auth'], payload)
//     const newAuthInfo = prop('payload', payload)
//     setToken(token)
//     setUserInfo({
//       ...authInfo,
//       ...newAuthInfo,
//     })
//     yield put(actions.authUserInfo())
//     if (isUser.isBachelorStudent(prop('userInfo', authInfo)) || isUser.isTestStudent(prop('userInfo', authInfo))) {
//       yield put(push(ROUTE.STUDENT_DASHBOARD))
//     } else {
//       yield put(push(ROUTE.ACCOUNT))
//     }
//   })
// }

function* watchAccountRequiredActions() {
  yield takeEvery(actionTypes[ACCOUNT_REQUIRED_ACTIONS].pending, function* ({ payload }) {
    try {
      const { data } = yield call(API.accountRequiredAction, dissoc('fromQuery', payload))

      yield put({
        type: actionTypes[ACCOUNT_REQUIRED_ACTIONS].fulfilled,
        payload: data,
        args: payload,
      })
    } catch (error) {
      yield put({
        type: actionTypes[ACCOUNT_REQUIRED_ACTIONS].rejected,
        payload: error,
      })
    }
  })
}

function watchAccountRequiredActionsFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ACCOUNT_REQUIRED_ACTIONS],
    filter: {
      filterAction: actions.accountList,
    },
  })
}

function watchAccountProfilePicUpdate() {
  return watchSaga({
    action: actionTypes[ACCOUNT_PROFILE_PIC],
    api: API.accountProfilePic,
  })
}

function* watchAccountProfilePicUpdateFulfilled() {
  yield takeEvery(actionTypes[ACCOUNT_PROFILE_PIC].fulfilled, function* () {
    // yield put(actions.dashboardStudent())
    toast.success(<SuccessToast text='Profile updated!' />)
  })
}

function* watchAccountProfilePicUpdateRejected() {
  yield takeEvery(actionTypes[ACCOUNT_PROFILE_PIC].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

function watchAccountProfileUpdate() {
  return watchSaga({
    action: actionTypes[ACCOUNT_PROFILE_UPDATE],
    api: API.accountProfileUpdate,
  })
}

function* watchAccountProfileUpdateFulfilled() {
  yield takeEvery(actionTypes[ACCOUNT_PROFILE_UPDATE].fulfilled, function* () {
    // yield put(actions.dashboardStudent())
    toast.success(<SuccessToast text='Profile updated!' />)
  })
}

function watchAccountProfileUpdateRejected() {
  return watchSagaRejected({
    action: actionTypes[ACCOUNT_PROFILE_UPDATE],
    message: true,
  })
}

function watchAccountPasswordUpdate() {
  return watchSaga({
    action: actionTypes[ACCOUNT_PASSWORD_UPDATE],
    api: API.accountUpdatePassword,
  })
}

function watchAccountPasswordUpdateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ACCOUNT_PASSWORD_UPDATE],
    message: {
      title: 'Successfully updated!',
    },
  })
}

function watchAccountPasswordUpdateRejected() {
  return watchSagaRejected({
    action: actionTypes[ACCOUNT_PASSWORD_UPDATE],
    message: true,
  })
}

export function* accountSaga() {
  yield all([
    fork(watchAccount),
    fork(watchAccountCreate),
    fork(watchAccountCreateFulfilled),
    fork(watchAccountCreateRejected),
    fork(watchAccountDelete),
    fork(watchAccountDeleteFulfilled),
    fork(watchAccountDeleteRejected),
    fork(watchAccountList),
    fork(watchAccountAll),
    fork(watchAccountPasswordReset),
    fork(watchAccountRequiredActions),
    fork(watchAccountRequiredActionsFulfilled),
    fork(watchAccountProfilePicUpdate),
    fork(watchAccountProfilePicUpdateFulfilled),
    fork(watchAccountProfilePicUpdateRejected),
    fork(watchAccountProfileUpdate),
    fork(watchAccountProfileUpdateFulfilled),
    fork(watchAccountProfileUpdateRejected),
    fork(watchAccountPasswordUpdate),
    fork(watchAccountPasswordUpdateFulfilled),
    fork(watchAccountPasswordUpdateRejected),
  ])
}
