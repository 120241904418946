import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

// export const courseCampaign: ApiFunc<`${typeof API.COURSE_CAMPAIGN}{id}`, 'get'> = data =>
//   instance.get(`${API.COURSE_CAMPAIGN}${'id' in data ? prop('id', data) : data.name}`, {
//     params: 'id' in data ? dissoc('id', data) : dissoc('name', data),
//   })

// export const courseCampaignCreate: ApiFunc<`${typeof API.COURSE_CAMPAIGN}`, 'post'> = data =>
//   instance.post(API.COURSE_CAMPAIGN, data)

// export const courseCampaignDelete: ApiFunc<`${typeof API.COURSE_CAMPAIGN}{id}`, 'delete'> = data =>
//   instance.delete(`${API.COURSE_CAMPAIGN}${'id' in data ? prop('id', data) : data.name}`)

// export const courseCampaignList: ApiFunc<`${typeof API.COURSE_CAMPAIGN}`, 'get'> = data =>
//   instance.get(API.COURSE_CAMPAIGN, {
//     params: data,
//   })

export const courseCampaignAll: ApiFunc<`${typeof API.COURSE_CAMPAIGN_ALL}`, 'get'> = data =>
  instance.get(API.COURSE_CAMPAIGN_ALL, {
    params: data,
  })

export const lmsCourseCampaignAll: ApiFunc<`${typeof API.LMS_COURSE_CAMPAIGN_ALL}`, 'get'> = data =>
  instance.get(API.LMS_COURSE_CAMPAIGN_ALL, {
    params: data,
  })
