import {
  LAYOUT_CONTENT_WIDTH,
  LAYOUT_MENU_COLLAPSED,
  LAYOUT_MENU_DIRECTION,
  LAYOUT_MENU_HIDDEN,
  LAYOUT_RTL,
  LAYOUT_SKIN,
} from './actionTypes'

// ** Handles Layout Content Width (full / boxed)
export const layoutContentWidth = value => dispatch => dispatch({ type: LAYOUT_CONTENT_WIDTH, value })

// ** Handles Menu Collapsed State (Bool)
export const layoutMenuCollapsed = value => dispatch => dispatch({ type: LAYOUT_MENU_COLLAPSED, value })

// ** Handles Menu Hidden State (Bool)
export const layoutMenuHidden = value => dispatch => dispatch({ type: LAYOUT_MENU_HIDDEN, value })

// ** Handles RTL (Bool)
export const layoutRTL = value => dispatch => dispatch({ type: LAYOUT_RTL, value })

// ** tracks current skin
export const trackCurrentSkin = payload => dispatch => dispatch({ type: LAYOUT_SKIN, payload })

export const layoutMenuDirection = payload => ({
  type: LAYOUT_MENU_DIRECTION,
  payload,
})
