import { pathOr } from 'ramda/es'
import { all, fork, takeEvery } from 'redux-saga/effects'

import { WatchSaga, getPageSaga, watchSaga, watchSagaFulfilled, watchSagaRejected } from '../../../helpers/customSaga'
import * as actions from '../../../redux/actions'
import * as API from '../api'
import actionTypes, {
  ASSESSMENT,
  ASSESSMENT_ALL,
  ASSESSMENT_CREATE,
  ASSESSMENT_DELETE,
  ASSESSMENT_GROUPS_IMPORT_BY_STUDENT,
  ASSESSMENT_IMPORT_PAPER_BASED,
  ASSESSMENT_LIST,
  ASSESSMENT_RESULTS,
  ASSESSMENT_TYPES,
} from '../constants/actionTypes'

const assessmentSaga = new WatchSaga({ actionType: actionTypes[ASSESSMENT], api: API.assessmentGetById })

const assessmentTypesSaga = new WatchSaga({ actionType: actionTypes[ASSESSMENT_TYPES], api: API.assessmentTypes })

const assessmentImportPaperBasedSaga = new WatchSaga({
  actionType: actionTypes[ASSESSMENT_IMPORT_PAPER_BASED],
  api: API.assessmentImportPaperBased,
})

const assessmentGroupsImportByStudentSaga = new WatchSaga({
  actionType: actionTypes[ASSESSMENT_GROUPS_IMPORT_BY_STUDENT],
  api: API.assessmentGroupsImportByStudent,
})

function watchAssessmentResults() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_RESULTS],
    api: API.assessmentResults,
  })
}

function watchAssessmentResultsRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_RESULTS],
    message: true,
  })
}

const assessmentListSaga = new WatchSaga({ actionType: actionTypes[ASSESSMENT_LIST], api: API.assessmentList })

function watchAssessmentAll() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_ALL],
    api: API.assessmentAll,
  })
}

function watchAssessmentAllRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_ALL],
    message: true,
  })
}

function watchAssessmentCreate() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_CREATE],
    api: API.assessmentCreate,
  })
}

function watchAssessmentCreateRejected() {
  return watchSagaRejected({
    action: actionTypes[ASSESSMENT_CREATE],
    message: true,
  })
}

function watchAssessmentCreateFulfilled() {
  return watchSagaFulfilled({
    action: actionTypes[ASSESSMENT_CREATE],
    message: {
      description: 'Successfully created',
    },
    filter: {
      filterAction: actions.assessmentList,
    },
  })
}

function watchAssessmentDelete() {
  return watchSaga({
    action: actionTypes[ASSESSMENT_DELETE],
    api: API.assessmentDelete,
  })
}

function* watchAssessmentDeleteFulfilled() {
  yield takeEvery(actionTypes[ASSESSMENT_DELETE].fulfilled, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload)
    yield getPageSaga(actions.assessmentList)
  })
}

function* watchAssessmentDeleteRejected() {
  yield takeEvery(actionTypes[ASSESSMENT_DELETE].rejected, function* (payload) {
    const callback = pathOr(() => {}, ['args', 'callback'], payload)
    yield callback(payload, pathOr('Sorry cannot perform Your request', ['payload', 'message'], payload))
  })
}

export function* assessmentSagas() {
  yield all([
    fork(assessmentSaga.watch),
    fork(assessmentSaga.watchRejected),
    fork(assessmentSaga.watchFulfilled),
    fork(assessmentListSaga.watch),
    fork(assessmentListSaga.watchRejected),
    fork(assessmentListSaga.watchFulfilled),
    fork(watchAssessmentAll),
    fork(watchAssessmentAllRejected),
    fork(watchAssessmentCreate),
    fork(watchAssessmentCreateRejected),
    fork(watchAssessmentCreateFulfilled),
    fork(watchAssessmentDelete),
    fork(watchAssessmentDeleteFulfilled),
    fork(watchAssessmentDeleteRejected),
    fork(assessmentTypesSaga.watch),
    fork(assessmentTypesSaga.watchFulfilled),
    fork(assessmentTypesSaga.watchRejected),
    fork(assessmentImportPaperBasedSaga.watch),
    fork(assessmentImportPaperBasedSaga.watchFulfilled),
    fork(assessmentImportPaperBasedSaga.watchRejected),
    fork(assessmentGroupsImportByStudentSaga.watch),
    fork(assessmentGroupsImportByStudentSaga.watchFulfilled),
    fork(assessmentGroupsImportByStudentSaga.watchRejected),
    fork(watchAssessmentResults),
    fork(watchAssessmentResultsRejected),
  ])
}
