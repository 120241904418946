import { path } from 'ramda/es'
import { createSelector } from 'reselect'
import {
  GLOBAL_STATE,
  ACCOUNT,
  ACCOUNT_LIST,
  ACCOUNT_ALL,
  ACCOUNT_PROFILE_UPDATE,
  ACCOUNT_PASSWORD_UPDATE,
} from '../constants/state'

export const accountSelector = createSelector(path([GLOBAL_STATE, ACCOUNT]), data => data)

export const accountListSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_LIST]), data => data)

export const accountAllSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_ALL]), data => data)

export const accountProfileUpdateSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_PROFILE_UPDATE]), data => data)

export const accountPasswordUpdateSelector = createSelector(path([GLOBAL_STATE, ACCOUNT_PASSWORD_UPDATE]), data => data)

export const accountSelectors = {
  accountSelector,
  accountListSelector,
  accountAllSelector,
  accountProfileUpdateSelector,
  accountPasswordUpdateSelector,
}
