import { combineReducers } from 'redux'
import * as actionTypes from '../constants/actionTypes'
import * as STATE from '../constants/state'
import { createState } from '../../../helpers/createState'

const reducer = combineReducers({
  [STATE.TEACHING_GROUP]: createState(actionTypes.TEACHING_GROUP),
  [STATE.TEACHING_GROUP_CREATE]: createState(actionTypes.TEACHING_GROUP_CREATE),
  [STATE.TEACHING_GROUP_ALL]: createState(actionTypes.TEACHING_GROUP_ALL),
  [STATE.TEACHING_GROUP_LIST]: createState(actionTypes.TEACHING_GROUP_LIST),
  [STATE.TEACHING_GROUP_GRADES]: createState(actionTypes.TEACHING_GROUP_GRADES),
  [STATE.TEACHING_GROUP_STUDENTS]: createState(actionTypes.TEACHING_GROUP_STUDENTS),
  [STATE.TEACHING_GROUP_TEACHERS]: createState(actionTypes.TEACHING_GROUP_TEACHERS),
  [STATE.TEACHING_GROUP_TYPES]: createState(actionTypes.TEACHING_GROUP_TYPES),
})

export const TEACHING_GROUP = STATE.GLOBAL_STATE

export default reducer