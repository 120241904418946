import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import { GLOBAL_STATE, SUBJECT_ALL } from '../constants/actionTypes'

export const subjectAllSelector = createSelector(
  (state: RootState) => prop(GLOBAL_STATE, state),
  data => data[SUBJECT_ALL]
)
