import axios from 'axios'
import { pathOr } from 'ramda'
import { toast } from 'react-toastify'

export interface ApiError {
  message: string
}

export const toastError = (e: unknown) => {
  const error: { message: string; status?: number } = { message: 'An unknown error occurred: ' }
  if (axios.isAxiosError(e)) {
    const status = e.response?.status
    const message = e.response?.data || 'An error has happened.'
    toast.error(message, { style: { wordBreak: 'break-word' } })
    error.message = message
    error.status = status
  } else {
    toast.error(pathOr('Something went wrong', ['data', 'message'], e), { style: { wordBreak: 'break-word' } })
    console.error(error.message, e)
  }
  return error
}
