// ** Logo
// import logo from '@src/assets/images/logo/logo_dark.png'
import themeConfig from '@src/configs/themeConfig'

const SpinnerComponent = () => {
  const skin = localStorage.getItem('skin') || '"light"'
  const logo = skin === '"light"' ? themeConfig.app.appLogo : themeConfig.app.appLogoLight
  const backgroundColor = skin === '"light"' ? themeConfig.loader.color : themeConfig.loader.colorDark

  return (
    <div style={{ backgroundColor }} className='fallback-spinner vh-100'>
      <img className='fallback-logo' src={logo} alt='logo' />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
