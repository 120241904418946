import { WatchSaga, watchSaga, watchSagaRejected } from '@helpers/customSaga'
import { path, pathOr } from 'ramda'
import { all, fork, takeEvery } from 'redux-saga/effects'

import * as API from '../api'
import actionTypes, {
  STUDENT_ASSESSMENT,
  STUDENT_ASSESSMENT_SAVE,
  STUDENT_ASSESSMENT_SELECT,
  STUDENT_ASSESSMENT_SUBMIT,
} from '../constants/actionTypes'

const studentAssessmentSaga = new WatchSaga({ actionType: actionTypes[STUDENT_ASSESSMENT], api: API.studentAssessment })

const studentAssessmentSaveSaga = new WatchSaga({
  actionType: actionTypes[STUDENT_ASSESSMENT_SAVE],
  api: API.studentAssessmentSave,
})

const studentAssessmentSelectSaga = new WatchSaga({
  actionType: actionTypes[STUDENT_ASSESSMENT_SELECT],
  api: API.studentAssessmentSelect,
})

const studentAssessmentSubmitSaga = new WatchSaga({
  actionType: actionTypes[STUDENT_ASSESSMENT_SUBMIT],
  api: API.studentAssessmentSubmit,
})

export function* examSaga() {
  yield all([
    fork(studentAssessmentSaga.watch),
    fork(studentAssessmentSaga.watchFulfilled),
    fork(studentAssessmentSaga.watchRejected),
    fork(studentAssessmentSaveSaga.watch),
    fork(studentAssessmentSaveSaga.watchFulfilled),
    fork(studentAssessmentSaveSaga.watchRejected),
    fork(studentAssessmentSelectSaga.watch),
    fork(studentAssessmentSelectSaga.watchFulfilled),
    fork(studentAssessmentSelectSaga.watchRejected),
    fork(studentAssessmentSubmitSaga.watch),
    fork(studentAssessmentSubmitSaga.watchFulfilled),
    fork(studentAssessmentSubmitSaga.watchRejected),
  ])
}
