import { getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

export const studentAssessment = data =>
  getInstance().get(API.STUDENT_ASSESSMENT, {
    params: data,
  })

export const studentAssessmentSave = data => getInstance().post(API.STUDENT_ASSESSMENT_SAVE, data)

export const studentAssessmentSubmit = data => getInstance().post(API.STUDENT_ASSESSMENT_SUBMIT, data)

export const studentAssessmentSelect = data => getInstance().get(API.STUDENT_ASSESSMENT_SELECT, { params: data })
