import { createReducer } from './createReducer'
import { path } from 'ramda'

export const defaultState = {
  data: null,
  error: null,
  loading: false,
  failed: null,
}

const PENDING = '_PENDING'
const FULFILLED = '_FULFILLED'
const EDIT = '_EDIT'
const REJECTED = '_REJECTED'
const CLEAR = '_CLEAR'

export const actionPending = actionName => `${actionName}${PENDING}`
export const actionFulfilled = actionName => `${actionName}${FULFILLED}`
export const actionEdit = actionName => `${actionName}${EDIT}`

export const actionRejected = actionName => `${actionName}${REJECTED}`
export const actionClear = actionName => `${actionName}${CLEAR}`

export const createState = actionName => {
  return createReducer(defaultState, {
    [actionPending(actionName)](state) {
      return {
        ...state,
        loading: true,
        failed: null,
      }
    },

    [actionFulfilled(actionName)](state, action) {
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false,
        failed: false,
      }
    },

    [actionEdit(actionName)](state, action) {
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: false,
        failed: false,
      }
    },

    [actionRejected(actionName)](state, action) {
      return {
        ...state,
        data: null,
        error: {
          status: path(['payload', 'data', 'status'], action),
          message: path(['payload', 'data', 'message'], action),
        },
        loading: false,
        failed: true,
      }
    },

    [actionClear(actionName)]() {
      return defaultState
    },
  })
}
