import { toCamelCase } from '../../../helpers/toCamelCase'

export const CONTENT_LIST = 'CONTENT_LIST'
export const CONTENT_CREATE = 'CONTENT_CREATE'
export const CONTENT_DELETE = 'CONTENT_DELETE'
export const CONTENT_ALL = 'CONTENT_ALL'
export const CONTENT_ACTION = 'CONTENT_ACTION'
export const CONTENT = 'CONTENT'

export const GLOBAL_STATE = 'Content'

export const actionNames = {
  [toCamelCase(CONTENT_LIST)]: CONTENT_LIST,
  [toCamelCase(CONTENT_CREATE)]: CONTENT_CREATE,
  [toCamelCase(CONTENT_DELETE)]: CONTENT_DELETE,
  [toCamelCase(CONTENT_ALL)]: CONTENT_ALL,
  [toCamelCase(CONTENT_ACTION)]: CONTENT_ACTION,
  [toCamelCase(CONTENT)]: CONTENT,
}
