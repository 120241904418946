export const GLOBAL_STATE = 'Assessment'

export const ASSESSMENT_LIST = 'assessmentList'
export const ASSESSMENT_CREATE = 'assessmentCreate'
export const ASSESSMENT_DELETE = 'assessmentDelete'
export const ASSESSMENT = 'assessment'
export const ASSESSMENT_ALL = 'assessmentAll'
export const ASSESSMENT_TYPES = 'assessmentTypes'
export const ASSESSMENT_RESULTS = 'assessmentResults'
export const ASSESSMENT_IMPORT_PAPER_BASED = 'assessmentImportPaperBased'
export const ASSESSMENT_GROUPS_IMPORT_BY_STUDENT = 'assessmentGroupsImportByStudent'
