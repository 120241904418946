import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  COURSE_CAMPAIGN,
  COURSE_CAMPAIGN_ALL,
  COURSE_CAMPAIGN_CREATE,
  COURSE_CAMPAIGN_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const courseCampaignSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const courseCampaignAllSelector = createSelector(courseCampaignSelectors, prop(COURSE_CAMPAIGN_ALL))

// export const courseCampaignSelector = createSelector(courseCampaignSelectors, prop(COURSE_CAMPAIGN))

// export const courseCampaignListSelector = createSelector(courseCampaignSelectors, prop(COURSE_CAMPAIGN_LIST))

// export const courseCampaignCreateSelector = createSelector(courseCampaignSelectors, prop(COURSE_CAMPAIGN_CREATE))

export const courseCampaignAllOptionsSelector = createSelector(courseCampaignAllSelector, selected => {
  return {
    loading: selected.loading,
    error: selected.error,
    options: Array.isArray(selected.data) ? selected.data.map(item => ({ label: item.title, value: item.id })) : [],
  }
})
