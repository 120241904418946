import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from '@src/helpers/httpClient'

import {
  COURSE_CAMPAIGN,
  COURSE_CAMPAIGN_ALL,
  COURSE_CAMPAIGN_CREATE,
  COURSE_CAMPAIGN_DELETE,
  COURSE_CAMPAIGN_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'
import * as actionCreators from './actions'
import { defaultState, initialState } from './initialState'

export const COURSE_CAMPAIGNS = GLOBAL_STATE

export const { reducer: courseCampaignReducer } = createSlice({
  name: COURSE_CAMPAIGNS,
  initialState,
  reducers: {},
  extraReducers(builder) {
    // builder
    //   .addCase(actionCreators.courseCampaign.pending, state => {
    //     state[COURSE_CAMPAIGN].loading = true
    //   })
    //   .addCase(actionCreators.courseCampaign.rejected, (state, action) => {
    //     state[COURSE_CAMPAIGN].loading = false
    //     state[COURSE_CAMPAIGN].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.courseCampaign.fulfilled, (state, action) => {
    //     state[COURSE_CAMPAIGN].loading = false
    //     state[COURSE_CAMPAIGN].error = null
    //     state[COURSE_CAMPAIGN].data = action.payload
    //   })

    builder
      .addCase(actionCreators.courseCampaignAll.pending, state => {
        state[COURSE_CAMPAIGN_ALL].loading = true
      })
      .addCase(actionCreators.courseCampaignAll.rejected, (state, action) => {
        state[COURSE_CAMPAIGN_ALL].loading = false
        state[COURSE_CAMPAIGN_ALL].error = action.payload as AxiosError
      })
      .addCase(actionCreators.courseCampaignAll.fulfilled, (state, action) => {
        state[COURSE_CAMPAIGN_ALL].loading = false
        state[COURSE_CAMPAIGN_ALL].error = null
        state[COURSE_CAMPAIGN_ALL].data = action.payload
      })

    // builder
    //   .addCase(actionCreators.courseCampaignCreate.pending, state => {
    //     state[COURSE_CAMPAIGN_CREATE].loading = true
    //   })
    //   .addCase(actionCreators.courseCampaignCreate.rejected, (state, action) => {
    //     state[COURSE_CAMPAIGN_CREATE].loading = false
    //     state[COURSE_CAMPAIGN_CREATE].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.courseCampaignCreate.fulfilled, (state, action) => {
    //     state[COURSE_CAMPAIGN_CREATE].loading = false
    //     state[COURSE_CAMPAIGN_CREATE].error = null
    //     state[COURSE_CAMPAIGN_CREATE].data = action.payload
    //   })

    // builder
    //   .addCase(actionCreators.courseCampaignDelete.pending, state => {
    //     state[COURSE_CAMPAIGN_DELETE].loading = true
    //   })
    //   .addCase(actionCreators.courseCampaignDelete.rejected, (state, action) => {
    //     state[COURSE_CAMPAIGN_DELETE].loading = false
    //     state[COURSE_CAMPAIGN_DELETE].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.courseCampaignDelete.fulfilled, (state, action) => {
    //     state[COURSE_CAMPAIGN_DELETE].loading = false
    //     state[COURSE_CAMPAIGN_DELETE].error = null
    //     state[COURSE_CAMPAIGN_DELETE].data = action.payload
    //   })

    // builder
    //   .addCase(actionCreators.courseCampaignList.pending, state => {
    //     state[COURSE_CAMPAIGN_LIST].loading = true
    //   })
    //   .addCase(actionCreators.courseCampaignList.rejected, (state, action) => {
    //     state[COURSE_CAMPAIGN_LIST].loading = false
    //     state[COURSE_CAMPAIGN_LIST].error = action.payload as AxiosError
    //   })
    //   .addCase(actionCreators.courseCampaignList.fulfilled, (state, action) => {
    //     state[COURSE_CAMPAIGN_LIST].loading = false
    //     state[COURSE_CAMPAIGN_LIST].error = null
    //     state[COURSE_CAMPAIGN_LIST].data = action.payload
    //   })

    // builder.addCase(actionCreators.courseCampaignClear, state => {
    //   state[COURSE_CAMPAIGN] = defaultState()
    // })

    // builder.addCase(actionCreators.courseCampaignAllClear, state => {
    //   state[COURSE_CAMPAIGN_ALL] = defaultState()
    // })

    // builder.addCase(actionCreators.courseCampaignListClear, state => {
    //   state[COURSE_CAMPAIGN_LIST] = defaultState()
    // })
  },
})
