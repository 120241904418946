import { DefaultState } from '@src/redux/types'

import {
  COURSE_CAMPAIGN,
  COURSE_CAMPAIGN_ALL,
  COURSE_CAMPAIGN_CREATE,
  COURSE_CAMPAIGN_DELETE,
  COURSE_CAMPAIGN_LIST,
} from '../constants/actionTypes'
import * as API from '../constants/api'

export const defaultState = () => ({ loading: false, error: null, data: null })

export const initialState: () => {
  [COURSE_CAMPAIGN_ALL]: DefaultState<`${typeof API.COURSE_CAMPAIGN_ALL}`, 'get'>
  // [COURSE_CAMPAIGN]: DefaultState<`${typeof API.COURSE_CAMPAIGN}{id}`, 'get'>
  // [COURSE_CAMPAIGN_CREATE]: DefaultState<`${typeof API.COURSE_CAMPAIGN}`, 'post'>
  // [COURSE_CAMPAIGN_DELETE]: DefaultState<`${typeof API.COURSE_CAMPAIGN}{id}`, 'delete'>
  // [COURSE_CAMPAIGN_LIST]: DefaultState<`${typeof API.COURSE_CAMPAIGN}`, 'get'>
} = () => ({
  [COURSE_CAMPAIGN_ALL]: defaultState(),
  // [COURSE_CAMPAIGN]: defaultState(),
  // [COURSE_CAMPAIGN_CREATE]: defaultState(),
  // [COURSE_CAMPAIGN_DELETE]: defaultState(),
  // [COURSE_CAMPAIGN_LIST]: defaultState(),
})
