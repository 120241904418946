import { createAction } from '../../../helpers/createAction'

export const LESSON = 'LESSON'
export const LESSON_CREATE = 'LESSON_CREATE'
export const LESSON_DELETE = 'LESSON_DELETE'
export const LESSON_LIST = 'LESSON_LIST'
export const LESSON_ALL = 'LESSON_ALL'

export default {
  [LESSON]: createAction(LESSON),
  [LESSON_CREATE]: createAction(LESSON_CREATE),
  [LESSON_DELETE]: createAction(LESSON_DELETE),
  [LESSON_LIST]: createAction(LESSON_LIST),
  [LESSON_ALL]: createAction(LESSON_ALL),
}
