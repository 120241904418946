import actionTypes, {
  ASSESSMENT,
  ASSESSMENT_ALL,
  ASSESSMENT_CREATE,
  ASSESSMENT_DELETE,
  ASSESSMENT_GROUPS_IMPORT_BY_STUDENT,
  ASSESSMENT_IMPORT_PAPER_BASED,
  ASSESSMENT_LIST,
  ASSESSMENT_RESULTS,
  ASSESSMENT_TYPES,
} from '../constants/actionTypes'

export const assessment = payload => ({
  type: actionTypes[ASSESSMENT].pending,
  payload,
})

export const assessmentClear = () => ({
  type: actionTypes[ASSESSMENT].clear,
})

export const assessmentList = payload => ({
  type: actionTypes[ASSESSMENT_LIST].pending,
  payload,
})

export const assessmentListClear = () => ({
  type: actionTypes[ASSESSMENT_LIST].clear,
})

export const assessmentAll = payload => ({
  type: actionTypes[ASSESSMENT_ALL].pending,
  payload,
})

export const assessmentAllClear = () => ({
  type: actionTypes[ASSESSMENT_ALL].clear,
})

export const assessmentDelete = payload => ({
  type: actionTypes[ASSESSMENT_DELETE].pending,
  payload,
})

export const assessmentCreate = payload => ({
  type: actionTypes[ASSESSMENT_CREATE].pending,
  payload,
})

export const assessmentTypes = payload => ({
  type: actionTypes[ASSESSMENT_TYPES].pending,
  payload,
})

export const assessmentTypesClear = () => ({
  type: actionTypes[ASSESSMENT_TYPES].clear,
})

export const assessmentResults = payload => ({
  type: actionTypes[ASSESSMENT_RESULTS].pending,
  payload,
})

export const assessmentResultsClear = () => ({
  type: actionTypes[ASSESSMENT_RESULTS].clear,
})

export const assessmentImportPaperBased = payload => ({
  type: actionTypes[ASSESSMENT_IMPORT_PAPER_BASED].pending,
  payload,
})

export const assessmentImportPaperBasedClear = () => ({
  type: actionTypes[ASSESSMENT_IMPORT_PAPER_BASED].clear,
})

export const assessmentGroupsImportByStudent = payload => ({
  type: actionTypes[ASSESSMENT_GROUPS_IMPORT_BY_STUDENT].pending,
  payload,
})

export const assessmentGroupsImportByStudentClear = () => ({
  type: actionTypes[ASSESSMENT_GROUPS_IMPORT_BY_STUDENT].clear,
})
