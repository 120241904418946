import { path, pathOr } from 'ramda/es'
import { toast } from 'react-toastify'
import { all, fork, takeEvery } from 'redux-saga/effects'

import { ErrorToast, SuccessToast } from '../../../components/Toasts'
import { WatchSaga, watchSaga, watchSagaRejected } from '../../../helpers/customSaga'
import excelReportApis from '../api'
import { EXCEL_REPORT } from '../constants/actionTypes'
import { actionTypes } from './reduxEssentials'

const excelReportSaga = new WatchSaga({ actionType: actionTypes[EXCEL_REPORT], api: excelReportApis[EXCEL_REPORT] })

//function watchExcelReport() {
//  return watchSaga({
//    action: actionTypes[EXCEL_REPORT],
//    api: excelReportApis[EXCEL_REPORT],
//  })
//}

//function* watchExcelReportRejected() {
//  yield takeEvery(actionTypes[EXCEL_REPORT].rejected, function* (action) {
//    const onRejected = pathOr(() => {}, ['args', 'onRejected'], action)
//    const description = path(['message', 'payload'], action)
//    yield onRejected(action)
//    toast.error(<ErrorToast text={description} />)
//  })
//}

//function* watchExcelReportFulfilled() {
//  yield takeEvery(actionTypes[EXCEL_REPORT].fulfilled, function* (action) {
//    const onFulfilled = pathOr(() => {}, ['args', 'onFulfilled'], action)
//    yield onFulfilled()
//    toast.success(<SuccessToast text='Successfully generated' />)
//  })
//}

export function* excelReportSagas() {
  yield all([fork(excelReportSaga.watch), fork(excelReportSaga.watchFulfilled), fork(excelReportSaga.watchRejected)])
}
