import { ApiFunc, getInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const instance = getInstance()

export const groupAll: ApiFunc<`${typeof API.GROUP_ALL}`, 'get'> = data =>
  instance.get(API.GROUP_ALL, {
    params: data,
  })

export const lmsGroupAll: ApiFunc<`${typeof API.LMS_GROUP_ALL}`, 'get'> = data =>
  instance.get(API.LMS_GROUP_ALL, {
    params: data,
  })

// export const groupList: ApiFunc<`${typeof API.GROUP}`, 'get'> = data => instance.get(API.GROUP, { params: data })

// export const groupShuffle: ApiFunc<`${typeof API.GROUP_SHUFFLE}`, 'post'> = data =>
//   instance.post(API.GROUP_SHUFFLE, data)

// export const groupStudentTransfer: ApiFunc<`${typeof API.GROUP_STUDENT_TRANSFER}`, 'post'> = data =>
//   instance.post(API.GROUP_STUDENT_TRANSFER, data)

// export const groupEdit: ApiFunc<`${typeof API.GROUP}`, 'post'> = data => instance.post(API.GROUP, data)

// export const groupDelete: ApiFunc<`${typeof API.GROUP}{id}`, 'delete'> = data =>
//   instance.delete(`${API.GROUP}${'id' in data ? data.id : data.name}`)

// export const groupAssign: ApiFunc<`${typeof API.GROUP_ASSIGN}`, 'post'> = data => instance.post(API.GROUP_ASSIGN, data)

// export const groupAssignedList: ApiFunc<`${typeof API.GROUP_ASSIGN}`, 'get'> = data =>
//   instance.get(API.GROUP_ASSIGN, { params: data })

// export const groupExport: ApiFunc<`${typeof API.GROUP_EXPORT}`, 'get'> = data =>
//   instance.get(API.GROUP_EXPORT, { params: data })
